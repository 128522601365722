import { Fragment } from 'react';

import { useTranslation } from 'react-i18next';

interface Props {
  text: string;
}

export default function Translations({ text }: Props) {
  // ** Hook
  const { t } = useTranslation();

  return <Fragment>{t(text)}</Fragment>;
}

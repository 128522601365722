import { Fragment, Dispatch, ElementType, SetStateAction, useState, useEffect, createElement } from 'react';

import { toast } from 'sonner';

import { useQuery, useMutation } from '@tanstack/react-query';
import actionService from '@/services/http/ActionService';

import { useForm } from 'react-hook-form';
import { FieldValues } from 'react-hook-form/dist/types/fields';
import { zodResolver } from '@hookform/resolvers/zod';

import { ZodSchema } from 'zod';

import { FormAction, IForm, IFormExtras } from '@/components/forms/Form';

import Loading from '@/components/utilities/Loading';
import ConfirmDialog from '@/components/dialogs/ConfirmDialog';
import FormDialog, { IFormDialogProps } from '@/components/dialogs/FormDialog';

import LoadingPortal from '@/components/utilities/LoadingPortal';
import { removePropertiesFromObject } from '@/utilities/removePropertiesFromObject';

export interface IEditActionDialogProps<TValue extends FieldValues, TForm>
  extends Omit<
    IFormDialogProps<TValue>,
    'title' | 'open' | 'loading' | 'onSubmit' | 'onClose' | 'children' | 'formContext'
  > {
  title?: string;
  url: string;
  urlGet?: string;
  urlGetSuffix?: string;
  urlPutSuffix?: string;
  includeIdInGetRequest?: boolean;
  includeIdInPutRequest?: boolean;
  id: string | number;
  form: ElementType<TForm>;
  validationSchema?: ZodSchema<TValue>;
  onSuccess?: () => void;
  onError?: () => void;
  onCancel?: () => void;
  onBeforeSubmit?: (url: string, values: TValue, action: FormAction) => { url: string; values: any };
  onRequestNewProps?: () => void;
  confirm?: boolean;
  confirmMessage?: string;
  removeUndefined?: boolean;
  removeNull?: boolean;
  removeEmpty?: boolean;
  propData?: TForm extends IForm<unknown, infer A, unknown> ? A & IFormExtras<TValue> : never;
  setOpen: Dispatch<SetStateAction<boolean>>;
  valuesTransformer?: (values: any) => TValue;
}

export default function EditActionDialog<TValue extends FieldValues, TForm extends IForm<unknown>>(
  props: IEditActionDialogProps<TValue, TForm>
) {
  const {
    title = 'Edit',
    url,
    urlGet = url,
    urlGetSuffix,
    urlPutSuffix,
    includeIdInPutRequest = true,
    includeIdInGetRequest = true,
    id,
    form,
    validationSchema,
    validationMode = 'onChange',
    maxWidth = 'sm',
    onSuccess,
    onError,
    onCancel,
    onBeforeSubmit,
    onRequestNewProps,
    confirm = false,
    confirmMessage,
    removeUndefined = false,
    removeNull = false,
    removeEmpty = false,
    propData,
    setOpen,
    valuesTransformer,
    shouldUnregister,
    ...rest
  } = props;

  const [openConfirm, setOpenConfirm] = useState(false);
  const [formData, setFormData] = useState<TValue | undefined>();

  const query = useQuery({
    queryFn: () => actionService.getAction(id, urlGet, includeIdInGetRequest, urlGetSuffix),
    queryKey: ['actionService.getAction', id, urlGet, includeIdInGetRequest, urlGetSuffix],
    enabled: id !== 0 && id !== undefined && propData?.values === undefined,
    initialData: propData?.values !== undefined ? propData?.values : undefined,
    gcTime: 0,
  });

  const mutation = useMutation({ mutationFn: actionService.editAction });

  useEffect(() => {
    if (query.isError) {
      setOpen(false);
      onError?.();
    }
  }, [query.isError, setOpen, onError]);

  const formMethods = useForm<TValue>({
    shouldUnregister: shouldUnregister,
    mode: validationMode,
    resolver: validationSchema ? zodResolver(validationSchema) : undefined,
  });

  const { reset } = formMethods;

  useEffect(() => {
    if (query.data) {
      reset(valuesTransformer !== undefined ? valuesTransformer(query.data) : query.data);
    }
  }, [reset, valuesTransformer, query.data]);

  if (query.isFetching) return <LoadingPortal />;

  async function handleFormSubmit(data?: TValue) {
    if (!data) {
      throw new Error('data cannot be undefined');
    }

    let newUrl = url;
    let newData = confirm ? formData : data;

    if (onBeforeSubmit) {
      const result = onBeforeSubmit(url, data, 'edit');

      newUrl = result.url;
      newData = result.values;
    }

    newData = removePropertiesFromObject(newData, removeUndefined, removeNull, removeEmpty, true);

    try {
      await mutation.mutateAsync({
        id: id,
        url: newUrl,
        data: newData,
        urlSuffix: urlPutSuffix,
        includeIdInRequest: includeIdInPutRequest,
      });

      toast.success('The record was successfully edited.');

      setOpenConfirm(false);
      setOpen(false);

      onSuccess?.();
    } catch (error) {
      onError?.();
    }
  }

  async function handleConfirmation(data: any) {
    setOpenConfirm(true);
    setFormData(data);
  }

  function handleOnCancel() {
    setOpen(false);
    reset();
    onCancel?.();
  }

  function renderForm() {
    if (!query.data) return <Loading variant='form' />;

    const newContext: TForm = {
      context: { action: 'edit' },
      originalData: query.data,
      propData: propData ?? {},
      onRequestNewProps,
    } as TForm;

    return createElement(form, { ...newContext }, null);
  }

  return (
    <Fragment>
      {confirm && (
        <ConfirmDialog
          loading={false}
          open={openConfirm}
          message={confirmMessage}
          onConfirm={() => handleFormSubmit(formData)}
          onCancel={() => setOpenConfirm(false)}
        />
      )}
      <FormDialog
        title={title}
        loading={mutation.isPending}
        open={true}
        onSubmit={confirm ? handleConfirmation : handleFormSubmit}
        onClose={handleOnCancel}
        maxWidth={maxWidth}
        validationSchema={validationSchema}
        formContext={formMethods}
        {...rest}
      >
        {renderForm()}
      </FormDialog>
    </Fragment>
  );
}

import { Fragment, ElementType, useState } from 'react';

import { RawAxiosRequestConfig } from 'axios';

import { toast } from 'sonner';

import TooltipAction from '@/components/actions/shared/TooltipAction';
import ConfirmDialog from '@/components/dialogs/ConfirmDialog';

import actionService from '@/services/http/ActionService';

interface HttpActionProps {
  url: string;
  confirmDialogText?: string;
  confirmDialogTitle?: string;
  confirmDialog?: boolean;
  data?: any;
  httpVerb: 'GET' | 'POST' | 'PUT' | 'DELETE';
  httpConfig?: RawAxiosRequestConfig;
  onSuccess?: (data: any) => void;
  onError?: () => void;
  disable?: boolean;

  // ActionTooltip
  tooltip: string;
  icon: ElementType;
  displayAsMenu?: boolean;
  onClick?: () => void;
}

const HttpAction = (props: HttpActionProps) => {
  const {
    tooltip,
    url,
    confirmDialog = true,
    confirmDialogTitle,
    confirmDialogText,
    data,
    icon: Icon,
    httpVerb,
    httpConfig,
    onSuccess,
    onError,
    onClick,
    disable = false,
    displayAsMenu,
  } = props;

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const actionVerb = async () => {
    switch (httpVerb) {
      case 'GET':
        return await actionService.get(url, httpConfig);
      case 'POST':
        return await actionService.post(url, data, httpConfig);
      case 'PUT':
        return await actionService.put(url, data, httpConfig);
      case 'DELETE':
        return await actionService.delete(url, httpConfig);
      default:
        throw new Error('Incorrect case');
    }
  };

  const handleConfirmation = async () => {
    onClick?.();
    setLoading(true);

    try {
      const result = await actionVerb();
      toast.success('The action was successfully committed.');
      setLoading(false);
      setOpen(false);

      onSuccess?.(result.data);
    } catch (error) {
      setLoading(false);
      setOpen(false);

      onError?.();
    }
  };

  function handleTooltipClick() {
    setOpen(true);
    onClick?.();
  }

  const RenderIcon = () => {
    return confirmDialog ? (
      <TooltipAction
        title={tooltip}
        icon={Icon}
        onClick={handleTooltipClick}
        disabled={disable}
        displayAsMenu={displayAsMenu}
      />
    ) : (
      <TooltipAction title={tooltip} icon={Icon} onClick={handleConfirmation} displayAsMenu={displayAsMenu} />
    );
  };

  if (!open) return <RenderIcon />;

  return (
    <Fragment>
      <RenderIcon />
      <ConfirmDialog
        loading={loading}
        open={open}
        title={confirmDialogTitle}
        message={confirmDialogText}
        onConfirm={handleConfirmation}
        onCancel={() => setOpen(false)}
      />
    </Fragment>
  );
};

export default HttpAction;

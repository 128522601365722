import { Children, cloneElement, ElementType, Fragment, MouseEvent, useState, ReactElement } from 'react';

import { MdOutlineMoreVert } from 'react-icons/md';

import TooltipAction from '@/components/actions/shared/TooltipAction';
import Permissions, { PermissionAction, PermissionResource } from '@/components/utilities/Permissions';
import Menu from '@mui/material/Menu';

interface IMenuActionProps {
  resource: PermissionResource;
  action?: PermissionAction;

  // ActionTooltip
  tooltip: string;
  children: ReactElement[];
  icon?: ElementType;
  displayAsMenu?: boolean;
  onClick?: () => void;
}

export default function MenuAction(props: IMenuActionProps) {
  const { resource, action, tooltip, icon: Icon = MdOutlineMoreVert, displayAsMenu, children, onClick } = props;

  const [hidden, setHidden] = useState(true);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);

  const handleTooltipClick = (event: MouseEvent<HTMLElement>) => {
    setHidden(false);
    setAnchorEl(event.currentTarget);
  };

  const handleOnClose = () => {
    setAnchorEl(null);
  };

  const handleOnClick = () => {
    setHidden(true);
    setAnchorEl(document.body);
    onClick?.();
  };

  const childProps = {
    displayAsMenu: true,
    onClick: handleOnClick,
  };

  return (
    <Permissions resource={resource} action={action}>
      <TooltipAction title={tooltip} icon={Icon} onClick={handleTooltipClick} displayAsMenu={displayAsMenu} />
      <Menu anchorEl={anchorEl} open={open} onClose={handleOnClose} hidden={hidden}>
        {Children.map(children, (child) => {
          if (child.type === Fragment) {
            return cloneElement(child.props.children, childProps);
          }

          return cloneElement(child, childProps);
        })}
      </Menu>
    </Permissions>
  );
}

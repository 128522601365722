// ** This file has been modified - YC

// ** React Imports
import { useState, SyntheticEvent, Fragment } from 'react';

// ** Next Import
import { useRouter } from 'next/router';

// ** MUI Imports
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import Badge from '@mui/material/Badge';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { MdOutlineAutoStories, MdOutlineManageHistory } from 'react-icons/md';

// ** Icons Imports
import { BiPowerOff, BiUser } from 'react-icons/bi';
import { TbActivityHeartbeat } from 'react-icons/tb';

// ** Zustand
import useAuthStore from '@/stores/useAuthStore';

// ** Type Imports
import { Settings } from '@core/context/settingsContext';

// ** Permission Imports
import Permissions from '@/components/utilities/Permissions';
import permissions from '@/configs/permissions';

import Profile from '@/views/profile/Profile';

interface Props {
  settings: Settings;
}

// ** Styled Components
const BadgeContentSpan = styled('span')(({ theme }) => ({
  width: 8,
  height: 8,
  borderRadius: '50%',
  backgroundColor: theme.palette.success.main,
  boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
}));

const UserDropdown = (props: Props) => {
  // ** Props
  const { settings } = props;

  // ** States
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);

  // ** Hooks
  const router = useRouter();
  const removeTokens = useAuthStore((state) => state.removeTokens);
  const accessToken = useAuthStore((state) => state.accessToken);

  // ** Vars
  const { direction } = settings;
  const apiUrl = process.env.NEXT_PUBLIC_API_URL;
  const { token, decodedToken } = accessToken;
  const user = {
    fullName: decodedToken.userId !== '' ? `${decodedToken.firstName} ${decodedToken.lastName}` : '',
    initials: decodedToken.userId !== '' ? decodedToken.firstName[0] + decodedToken.lastName[0] : '',
    roles: decodedToken.roles.join(', '),
  };

  const handleDropdownOpen = (event: SyntheticEvent) => {
    setAnchorEl(event.currentTarget);
  };

  async function handleDropdownClose(url?: string) {
    if (url) {
      await router.push(url);
    }

    setAnchorEl(null);
  }

  const styles = {
    py: 2,
    px: 4,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    color: 'text.secondary',
    textDecoration: 'none',
    '& svg': {
      mr: 2,
      fontSize: '1.25rem',
      color: 'text.secondary',
    },
  };

  const handleLogout = async () => {
    removeTokens();
    await handleDropdownClose();
  };

  const swaggerNavigation = async () => {
    await navigator.clipboard.writeText(token);
    window.open(`${apiUrl}/swagger/index.html`, '_blank');
  };

  const hangfireNavigation = async () => {
    window.open(`${apiUrl}/hangfire?accessToken=${token}`, '_blank');
  };

  const healthNavigation = async () => {
    window.open(`${apiUrl}/_health?accessToken=${token}`, '_blank');
  };

  return (
    <Fragment>
      <Badge
        overlap='circular'
        onClick={handleDropdownOpen}
        sx={{ ml: 2, cursor: 'pointer' }}
        // badgeContent={<BadgeContentSpan />}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        <Avatar alt='User avatar' onClick={handleDropdownOpen} sx={{ width: 40, height: 40 }}>
          {user.initials}
        </Avatar>
      </Badge>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => handleDropdownClose()}
        sx={{ '& .MuiMenu-paper': { width: 230, mt: 4 } }}
        anchorOrigin={{ vertical: 'bottom', horizontal: direction === 'ltr' ? 'right' : 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: direction === 'ltr' ? 'right' : 'left' }}
      >
        <Box sx={{ py: 2, px: 4 }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Badge
              overlap='circular'
              badgeContent={<BadgeContentSpan />}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
            >
              <Avatar alt='User avatar' sx={{ width: '2.5rem', height: '2.5rem' }}>
                {user.initials}
              </Avatar>
            </Badge>
            <Box sx={{ ml: 3, display: 'flex', alignItems: 'flex-start', flexDirection: 'column' }}>
              <Typography sx={{ fontWeight: 500 }}>{user.fullName}</Typography>
              <Typography variant='body2' sx={{ color: 'text.secondary' }}>
                {user.roles}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Divider sx={{ mb: 1 }} />
        <Profile onClose={handleDropdownClose}>
          <MenuItem sx={{ p: 0 }}>
            <Box sx={styles}>
              <BiUser />
              Profile
            </Box>
          </MenuItem>
        </Profile>
        <Permissions resource={[permissions.internal.swagger, permissions.internal.hangfire]}>
          <Divider />
        </Permissions>
        <Permissions resource={permissions.internal.swagger}>
          <MenuItem sx={{ p: 0 }} onClick={() => handleDropdownClose()}>
            <Box sx={styles} onClick={swaggerNavigation}>
              <MdOutlineAutoStories />
              Swagger
            </Box>
          </MenuItem>
        </Permissions>
        <Permissions resource={permissions.internal.hangfire}>
          <MenuItem sx={{ p: 0 }} onClick={() => handleDropdownClose()}>
            <Box sx={styles} onClick={hangfireNavigation}>
              <MdOutlineManageHistory />
              Hangfire
            </Box>
          </MenuItem>
        </Permissions>
        <Permissions resource={permissions.internal.health}>
          <MenuItem sx={{ p: 0 }} onClick={() => handleDropdownClose()}>
            <Box sx={styles} onClick={healthNavigation}>
              <TbActivityHeartbeat />
              Health
            </Box>
          </MenuItem>
        </Permissions>
        <Divider />
        <MenuItem sx={{ p: 0 }} onClick={handleLogout}>
          <Box sx={styles}>
            <BiPowerOff />
            Sign Out
          </Box>
        </MenuItem>
      </Menu>
    </Fragment>
  );
};

export default UserDropdown;

import { Fragment, ElementType, ReactNode, useState } from 'react';

import { FieldValues } from 'react-hook-form/dist/types/fields';

import { MdOutlineAdd } from 'react-icons/md';

import { IForm } from '@/components/forms/Form';
import Permissions, { PermissionResource } from '@/components/utilities/Permissions';
import TooltipAction from '@/components/actions/shared/TooltipAction';

import AddActionDialog, { IAddActionDialogProps } from './AddActionDialog';

interface IAddActionProps<TValue extends FieldValues, TForm>
  extends Omit<IAddActionDialogProps<TValue, TForm>, 'setOpen'> {
  resource: PermissionResource;
  open?: boolean;
  children?: ReactNode;

  // ActionTooltip
  tooltip?: string;
  icon?: ElementType;
  iconSize?: number;
  displayAsMenu?: boolean;
  onClick?: () => void;
}

export default function AddActionProps<TValue extends FieldValues, TForm extends IForm<unknown>>(
  props: IAddActionProps<TValue, TForm>
) {
  const {
    resource,
    open: openProp = false,
    onClick,
    children,
    tooltip = 'Add',
    icon: Icon = MdOutlineAdd,
    iconSize,
    displayAsMenu,
    ...rest
  } = props;

  const [open, setOpen] = useState(openProp);

  function handleOnClick() {
    setOpen(true);
    onClick?.();
  }

  function renderIcon() {
    return (
      <Permissions resource={resource} action='Create'>
        {children ? (
          <div onClick={handleOnClick}>{children}</div>
        ) : (
          <TooltipAction
            title={tooltip}
            onClick={handleOnClick}
            icon={Icon}
            iconSize={iconSize}
            displayAsMenu={displayAsMenu}
          />
        )}
      </Permissions>
    );
  }

  if (!open) return renderIcon();

  return (
    <Fragment>
      {renderIcon()}
      <AddActionDialog setOpen={setOpen} {...rest} />
    </Fragment>
  );
}

var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"6d8dc39828a28fcbbd43179c5889fd42f06a02b5"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

import useAuthStore from './src/stores/useAuthStore';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: SENTRY_DSN || 'https://95a033a04a6a426ea37bb2ff4a77d8ed@o1288542.ingest.sentry.io/6505515',
    release: process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA, // Adjust this value in production, or use tracesSampler for greater control
    tracesSampleRate: 1.0,
    environment: process.env.NEXT_PUBLIC_ENV,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    beforeBreadcrumb(breadcrumb, hint) {
      if (breadcrumb.category === 'xhr') {
        breadcrumb.data = {
          response: hint?.xhr.response,
          request: hint?.xhr.__sentry_xhr__,
        };
      }
      return breadcrumb;
    },
    integrations: [
      new Sentry.Replay({
        maskAllInputs: false,
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
  });

  useAuthStore
    .getState()
    .getToken()
    .then((token) => {
      if (token) {
        Sentry.setUser({
          orderId: token.decodedToken?.userId,
          email: token.decodedToken?.email,
          firstName: token.decodedToken?.firstName,
          lastName: token.decodedToken?.lastName,
        });
      }
    });
}

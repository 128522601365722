import { ElementType } from 'react';

import { useRouter } from 'next/router';

import { MdOutlinePrint } from 'react-icons/md';

import TooltipAction from '../shared/TooltipAction';
import Permissions, { PermissionResource } from '@/components/utilities/Permissions';

interface IPrintActionProps {
  resource: PermissionResource;
  url: string;

  // ActionTooltip
  tooltip?: string;
  icon?: ElementType;
  iconSize?: number;
  displayAsMenu?: boolean;
}

export default function PrintAction(props: IPrintActionProps) {
  const { resource, tooltip = 'Print', icon: Icon = MdOutlinePrint, iconSize, url, displayAsMenu } = props;

  const router = useRouter();

  const handleOnClick = async () => {
    await router.push(url);
  };

  return (
    <Permissions resource={resource} action='Report'>
      <TooltipAction
        title={tooltip}
        onClick={handleOnClick}
        icon={Icon}
        iconSize={iconSize}
        displayAsMenu={displayAsMenu}
      />
    </Permissions>
  );
}

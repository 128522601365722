import { ElementType, MouseEvent, forwardRef, ForwardedRef } from 'react';

import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

interface ITooltipActionProps {
  title: string;
  icon: ElementType;
  iconSize?: number;
  onClick: (event: MouseEvent<HTMLElement>) => void;
  disabled?: boolean;

  // menu
  displayAsMenu?: boolean;
}

const TooltipAction = forwardRef((props: ITooltipActionProps, ref: ForwardedRef<HTMLButtonElement>) => {
  const { title, icon: Icon, iconSize = 24, onClick, displayAsMenu = false, disabled } = props;

  if (displayAsMenu) {
    return (
      <MenuItem onClick={onClick} disabled={disabled} sx={{ minHeight: 35, paddingLeft: 3, paddingRight: 3 }}>
        <ListItemIcon>
          <Icon size={iconSize} />
        </ListItemIcon>
        <ListItemText>{title}</ListItemText>
      </MenuItem>
    );
  }

  return (
    <Tooltip sx={{ m: 0.2 }} title={title} arrow placement='top'>
      <IconButton ref={ref} onClick={onClick} disabled={disabled}>
        <Icon size={iconSize} />
      </IconButton>
    </Tooltip>
  );
});

export default TooltipAction;

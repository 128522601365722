import { Fragment, ReactNode, useState, useEffect } from 'react';

import { useRouter } from 'next/router';

import useAuthStore from '@/stores/useAuthStore';

import usePermissions from '@/hooks/usePermissions';

interface IAuthGuardProps {
  authGuard?: boolean;
  authResource?: string | Array<string>;
  children: ReactNode;
}

export default function AuthGuard(props: IAuthGuardProps) {
  const { authGuard = true, authResource = 'any', children } = props;

  const [verified, setVerified] = useState(false);

  const router = useRouter();
  const permissions = usePermissions();
  const isAuthenticated = useAuthStore((state) => state.isAuthenticated);

  useEffect(() => {
    if (!router.isReady) return;

    if (authGuard && !isAuthenticated) {
      (async () => {
        if (router.asPath !== '/') {
          await router.push({
            pathname: '/auth/login',
            query: { returnUrl: router.asPath },
          });
        } else {
          await router.replace('/auth/login');
        }
      })();
    } else {
      setVerified(true);
    }
  }, [router, isAuthenticated, authGuard]);

  if (!verified) return null;

  if (router.pathname !== '/401' && !permissions.has(authResource)) {
    (async () => {
      await router.replace('/401');
    })();

    return <Fragment></Fragment>;
  }

  return <Fragment>{children}</Fragment>;
}

import { AxiosResponse, RawAxiosRequestConfig } from 'axios';
import HttpService from '@/services/HttpService';
import { GenericResponse } from '@/services/http/types';

interface IAddAction {
  url: string;
  data: any;
}

interface IEditAction {
  id: number | string;
  url: string;
  data: any;
  urlSuffix: string | undefined;
  includeIdInRequest: boolean;
}

interface IDeleteAction {
  url: string;
  id: number | string;
  urlDeleteSuffix?: string;
}

class ActionService extends HttpService {
  constructor() {
    super();

    this.get = this.get.bind(this);
    this.post = this.post.bind(this);
    this.put = this.put.bind(this);
    this.delete = this.delete.bind(this);

    this.getAction = this.getAction.bind(this);
    this.addAction = this.addAction.bind(this);
    this.editAction = this.editAction.bind(this);
    this.deleteAction = this.deleteAction.bind(this);
  }

  url = '';

  get<T>(url: string, config = {}): Promise<AxiosResponse<T>> {
    return this.getBase<T>(url, config);
  }

  post<T>(url: string, data = {}, config: RawAxiosRequestConfig = {}): Promise<AxiosResponse<T>> {
    return this.postBase<T>(url, data, config);
  }

  put<T>(url: string, data = {}, config: RawAxiosRequestConfig = {}): Promise<AxiosResponse<T>> {
    return this.putBase<T>(url, data, config);
  }

  delete<T>(url: string, config: RawAxiosRequestConfig = {}): Promise<AxiosResponse<T>> {
    return this.deleteBase<T>(url, config);
  }

  async getAction(id: number | string, url: string, includeIdInGetRequest: boolean, urlSuffix?: string) {
    let urlToUse = `${url}`;

    if (includeIdInGetRequest) urlToUse += `/${id}`;
    if (urlSuffix) urlToUse += `/${urlSuffix}`;

    const response = await this.getBase<any>(urlToUse);

    return response.data;
  }

  addAction = async ({ url, data }: IAddAction): Promise<GenericResponse> => {
    const response = await this.postBase<GenericResponse>(`${url}`, data);

    return response.data;
  };

  editAction = async ({ id, url, data, urlSuffix, includeIdInRequest }: IEditAction): Promise<GenericResponse> => {
    let urlToUse = includeIdInRequest ? `${url}/${id}` : `${url}`;

    if (urlSuffix) urlToUse += `/${urlSuffix}`;

    const response = await this.putBase<GenericResponse>(urlToUse, data);

    return response.data;
  };

  deleteAction = async ({ url, id, urlDeleteSuffix }: IDeleteAction): Promise<GenericResponse> => {
    let urlFinal = `${url}/${id}`;

    if (urlDeleteSuffix) urlFinal += `/${urlDeleteSuffix}`;

    const response = await this.deleteBase<GenericResponse>(urlFinal);

    return response.data;
  };
}

export default new ActionService();

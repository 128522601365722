// ** React Imports
import { ReactNode } from 'react';

// ** Next Imports
import Head from 'next/head';
import { Router } from 'next/router';
import type { NextPage } from 'next';
import type { AppProps } from 'next/app';

// ** Loader Import
import NProgress from 'nprogress';

// ** Emotion Imports
import { CacheProvider } from '@emotion/react';
import type { EmotionCache } from '@emotion/cache';

// ** MUI
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LicenseInfo } from '@mui/x-license-pro';

// ** Config Imports
import '@/configs/i18n';
import themeConfig from '@/configs/themeConfig';

// ** Component Imports
import ThemeComponent from '@/@core/theme/ThemeComponent';

import UserLayout from '@/layouts/UserLayout';
import AuthGuard from '@/layouts/auth/AuthGuard';

// ** Contexts
import { SettingsConsumer, SettingsProvider } from '@core/context/settingsContext';

// ** React Query
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

// ** Notifications
import Toaster from '@/components/utilities/Toaster';

// ** Utils Imports
import { createEmotionCache } from '@core/utils/create-emotion-cache';

// ** React Perfect Scrollbar Style
import 'react-perfect-scrollbar/dist/css/styles.css';

// ** Global css styles
import '../styles/globals.css';

import 'reactflow/dist/style.css';

LicenseInfo.setLicenseKey(
  '87fb3c0d8fd628f68b448fb2a61d1be3Tz02NjM2NSxFPTE3MTU1MzY4OTg3ODQsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI='
);

// ** Extend App Props with Emotion
type ExtendedAppProps = AppProps & {
  Component: NextPage & {
    authGuard?: boolean;
    authResource?: string;
    contentHeightFixed?: boolean;
    getLayout?: (page: ReactNode) => ReactNode;
    setConfig?: () => any;
  };
  emotionCache: EmotionCache;
};

const clientSideEmotionCache = createEmotionCache();

// ** Pace Loader
if (themeConfig.routingLoader) {
  Router.events.on('routeChangeStart', () => {
    NProgress.start();
  });
  Router.events.on('routeChangeError', () => {
    NProgress.done();
  });
  Router.events.on('routeChangeComplete', () => {
    NProgress.done();
  });
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: process.env.NODE_ENV === 'production',
      retry: false,
    },
  },
});

// ** Configure JSS & ClassName
const App = (props: ExtendedAppProps) => {
  const { Component, emotionCache = clientSideEmotionCache, pageProps } = props;

  // Variables
  const contentHeightFixed = Component.contentHeightFixed ?? false;
  const getLayout =
    Component.getLayout ?? ((page) => <UserLayout contentHeightFixed={contentHeightFixed}>{page}</UserLayout>);

  const setConfig = Component.setConfig ?? undefined;

  const subTitle = 'Simplifying the process';

  return (
    <CacheProvider value={emotionCache}>
      <Head>
        <title>{`${themeConfig.templateName} - ${subTitle}`}</title>
        <meta name='description' content={`${themeConfig.templateName} – ${subTitle}`} />
        <meta name='keywords' content={subTitle} />
        <meta name='viewport' content='width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no' />
      </Head>

      <QueryClientProvider client={queryClient}>
        <SettingsProvider {...(setConfig ? { pageSettings: setConfig() } : {})}>
          <SettingsConsumer>
            {({ settings }) => {
              return (
                <ThemeComponent settings={settings}>
                  <AuthGuard authGuard={Component.authGuard} authResource={Component.authResource}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      {getLayout(<Component {...pageProps} />)}
                    </LocalizationProvider>
                  </AuthGuard>
                  <Toaster />
                </ThemeComponent>
              );
            }}
          </SettingsConsumer>
        </SettingsProvider>
        <ReactQueryDevtools initialIsOpen={false} buttonPosition='bottom-left' />
      </QueryClientProvider>
    </CacheProvider>
  );
};

export default App;

import { Portal } from '@mui/base/Portal';
import Backdrop from '@mui/material/Backdrop';

const LoadingPortal = () => {
  return (
    <Portal>
      <Backdrop sx={{ zIndex: 9000 }} open={true}>
        <object type='image/svg+xml' data='/images/logos/logo-loading.svg' style={{ width: 120 }}>
          svg-animation
        </object>
      </Backdrop>
    </Portal>
  );
};

export default LoadingPortal;

export default {
  account: {
    user: 'AccountUser',
    role: 'AccountRole',
  },
  audit: {
    log: 'AuditLog',
  },
  internal: {
    swagger: 'Swagger',
    hangfire: 'Hangfire',
    health: 'Health',
  },
  sales: {
    quote: 'SalesQuote',
    order: 'SalesOrder',
    customer: 'SalesCustomer',
    project: 'SalesProject',
    mark: 'SalesMark',
  },
  production: {
    workOrder: 'ProductionWorkOrder',
    process: 'ProductionProcess',
  },
  inventory: {
    dashboard: 'InventoryDashboard',
    calendar: 'InventoryCalendar',
    item: 'InventoryItem',
    attribute: 'InventoryAttribute',
    itemColor: 'InventoryItemColor',
    itemGroup: 'InventoryItemGroup',
    itemUnit: 'InventoryItemUnit',
    itemTransfer: 'InventoryItemTransfer',
    itemAdjustment: 'InventoryItemAdjustment',
    warehouse: 'InventoryWarehouse',
    location: 'InventoryLocation',
    rack: 'InventoryRack',
    bundle: 'InventoryBundle',
    vendor: 'InventoryVendor',
    purchaseOrder: 'InventoryPurchaseOrder',
    purchaseOrderReceipt: 'InventoryPurchaseOrderReceipt',
  },
  accounting: {
    bill: 'AccountingBill',
    tax: 'AccountingTax',
    qbItem: 'AccountingQbItem',
    qbServer: 'AccountingQbServer',
    qbJob: 'AccountingQbJob',
  },
  setup: {
    frame: 'SetupFrame',
    glass: 'SetupGlass',
    grid: 'SetupGrid',
    product: 'SetupProduct',
    item: 'SetupItem',
    billOfMaterial: 'SetupBillOfMaterial',
    process: 'SetupProcess',
  },
  scanner: {
    bundle: 'ScannerBundle',
  },
  legacy: {
    bundle: 'LegacyBundle',
    process: {
      released: 'LegacyReleased',
      cutting: 'LegacyCutting',
      punching: 'LegacyPunching',
      shipping: 'LegacyShipping',
      assembling: 'LegacyAssembling',
      screen: 'LegacyScreen',
      cnc: 'LegacyCnc',
      decoration: 'LegacyDecoration',
      glazing: 'LegacyGlazing',
      colonial: 'LegacyColonial',
      receive: 'LegacyReceive',
      delivery: 'LegacyDelivery',
    },
    glasses: {
      locateGlass: 'LegacyLocateGlass',
      takeGlass: 'LegacyTakeGlass',
      transferGlass: 'LegacyTransferGlass',
      receiveGlass: 'LegacyReceiveGlass',
      movePallet: 'LegacyMovePallet',
    },
  },
};

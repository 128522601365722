import { ElementType } from 'react';

import { MdOutlineMouse } from 'react-icons/md';

import TooltipAction from '@/components/actions/shared/TooltipAction';
import Permissions, { PermissionAction, PermissionResource } from '@/components/utilities/Permissions';

interface IButtonActionProps {
  resource: PermissionResource;
  action?: PermissionAction;

  // ActionTooltip
  tooltip: string;
  icon?: ElementType;
  iconSize?: number;
  displayAsMenu?: boolean;
  onClick: () => void;
}

export default function ButtonAction(props: IButtonActionProps) {
  const { resource, action, tooltip, icon: Icon = MdOutlineMouse, iconSize, onClick, displayAsMenu } = props;

  return (
    <Permissions resource={resource} action={action}>
      <TooltipAction title={tooltip} icon={Icon} iconSize={iconSize} onClick={onClick} displayAsMenu={displayAsMenu} />
    </Permissions>
  );
}

import { Fragment, useState } from 'react';

import { Control, Controller, FieldError, PathValue } from 'react-hook-form';
import { FieldValues } from 'react-hook-form/dist/types/fields';
import { FieldPath } from 'react-hook-form/dist/types';
import { RegisterOptions } from 'react-hook-form/dist/types/validator';

import TextField, { StandardTextFieldProps } from '@mui/material/TextField';
import FormHelperText from '@mui/material/FormHelperText';

import formatters from '@/utilities/Formatters';

interface ITextInputProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> extends Omit<StandardTextFieldProps, 'error'> {
  name: TName;
  label?: string;
  defaultValue?: PathValue<TFieldValues, TName> | undefined;
  control?: Control<TFieldValues>;
  zeroAsEmpty?: boolean;
  hideError?: boolean;
  shouldUnregister?: boolean;
  rules?: Omit<
    RegisterOptions<TFieldValues, TName>,
    'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled' | 'shouldUnregister'
  >;
}

function TextInput<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>(props: ITextInputProps<TFieldValues, TName>) {
  const {
    name,
    label = formatters.toSentenceCase(name),
    defaultValue,
    control,
    zeroAsEmpty = false,
    type,
    hideError = false,
    helperText,
    shouldUnregister = false,
    rules,
    onChange,
    disabled,
    ...rest
  } = props;

  const [valueChanged, setValueChanged] = useState(false);

  const getValue = (value: string | number) => {
    if (value === 0 && zeroAsEmpty && !valueChanged) {
      return '';
    }

    return value ?? '';
  };

  const getHelperText = (error: FieldError | undefined) => {
    if (!hideError && error?.message !== undefined) {
      return error.message;
    }

    if (helperText) return helperText;

    return undefined;
  };

  return (
    <Controller
      name={name}
      defaultValue={defaultValue}
      control={control}
      shouldUnregister={shouldUnregister}
      rules={rules}
      render={({ field, fieldState: { error } }) => {
        return (
          <Fragment>
            <TextField
              {...rest}
              {...field}
              label={label}
              type={type}
              value={getValue(field.value)}
              disabled={disabled}
              onChange={(event) => {
                const value = event.target.value;

                if (type === 'number' && value !== '') {
                  field.onChange(Number(value) as unknown as PathValue<TFieldValues, TName>);
                } else {
                  field.onChange(value as unknown as PathValue<TFieldValues, TName>);
                }

                if (!valueChanged) setValueChanged(true);

                onChange?.(event);
              }}
              error={!!error}
            />
            <FormHelperText error={!!error} sx={{ margin: '0 !important' }}>
              {getHelperText(error)}
            </FormHelperText>
          </Fragment>
        );
      }}
    />
  );
}

export default TextInput;

// ** This file has been modified - YC

// ** MUI Imports
import IconButton from '@mui/material/IconButton';

// ** Types Import
import { Mode } from '@core/layouts/types';
import { Settings } from '@core/context/settingsContext';

// ** Icons Imports
import { BiMoon, BiSun } from 'react-icons/bi';

interface Props {
  settings: Settings;
  saveSettings: (values: Settings) => void;
}

const ModeToggler = (props: Props) => {
  // ** Props
  const { settings, saveSettings } = props;

  const handleModeChange = (mode: Mode) => {
    saveSettings({ ...settings, mode: mode });
  };

  const handleModeToggle = () => {
    if (settings.mode === 'light') {
      handleModeChange('dark' as Mode);
    } else {
      handleModeChange('light' as Mode);
    }
  };

  return (
    <IconButton color='inherit' aria-haspopup='true' onClick={handleModeToggle}>
      {settings.mode === 'dark' ? <BiSun /> : <BiMoon />}
    </IconButton>
  );
};

export default ModeToggler;

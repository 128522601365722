// ** This file has been modified - YC

// ** React Import
import { useEffect } from 'react';

// ** Icons Imports
import { MdTranslate } from 'react-icons/md';

// ** Third Party Import
import { useTranslation } from 'react-i18next';

// ** Custom Components Imports
import OptionsMenu from '@core/components/option-menu';

// ** Type Import
import { Settings } from '@core/context/settingsContext';

interface Props {
  settings: Settings;
  saveSettings: (values: Settings) => void;
}

const LanguageDropdown = ({ settings, saveSettings }: Props) => {
  // ** Hook
  const { i18n } = useTranslation();

  // ** Vars
  const { layout } = settings;

  const handleLangItemClick = async (lang: 'en' | 'es') => {
    await i18n.changeLanguage(lang);
  };

  // ** Change html `lang` attribute when changing locale
  useEffect(() => {
    document.documentElement.setAttribute('lang', i18n.language);
  }, [i18n.language]);

  return (
    <OptionsMenu
      icon={MdTranslate}
      iconButtonProps={{ color: 'inherit', sx: { ...(layout === 'vertical' ? { mr: 0.75 } : { mx: 0.75 }) } }}
      menuProps={{
        sx: {
          '& .MuiMenu-paper': { mt: 4, minWidth: 130 },
          '& .MuiMenuItem-root:not(.Mui-selected)': { color: 'text.secondary' },
        },
      }}
      options={[
        {
          text: 'English',
          menuItemProps: {
            sx: { py: 2 },
            selected: i18n.language === 'en',
            onClick: async () => {
              await handleLangItemClick('en');
              saveSettings({ ...settings, direction: 'ltr' });
            },
          },
        },
        {
          text: 'Spanish',
          menuItemProps: {
            sx: { py: 2 },
            selected: i18n.language === 'es',
            onClick: async () => {
              await handleLangItemClick('es');
              saveSettings({ ...settings, direction: 'ltr' });
            },
          },
        },
      ]}
    />
  );
};

export default LanguageDropdown;

import useAuthStore from '@/stores/useAuthStore';

export default function usePermissions() {
  const permissions = useAuthStore((state) => state.permissions);

  function has(resource: 'any' | string | Array<string>, action?: string) {
    if (resource === 'any') {
      return action === undefined ? true : permissions.some((x) => x.action === action);
    }

    if (typeof resource === 'string') {
      return action === undefined || action === 'any'
        ? permissions.some((x) => x.resource === resource)
        : permissions.some((x) => x.resource === resource && x.action === action);
    } else if (Array.isArray(resource)) {
      return action === undefined || action === 'any'
        ? permissions.some((x) => resource.includes(x.resource))
        : permissions.some((x) => resource.includes(x.resource) && x.action === action);
    }

    return false;
  }

  return {
    has,
  };
}

import axios, { AxiosResponse, RawAxiosRequestConfig } from 'axios';

import { toast } from 'sonner';

import useAuthStore from '@/stores/useAuthStore';
import { GenericErrorResponse } from './http/types';

const unAuthorizedStatus = [401];
const nonValidatedRoutes = ['/auth/login', '/auth/forgot-password'];
const nonValidatedApi = ['/v1/Auth/Login', '/v1/Users/ForgotPassword'];

function validateRouteCheck(route: string): boolean {
  let validationToggle = false;

  const routeCheck = nonValidatedRoutes.find((x) => x === route);

  if (routeCheck) validationToggle = true;

  return validationToggle;
}

function validateApiCheck(url: string): boolean {
  let validationToggle = false;

  const urlCheck = nonValidatedApi.find((x) => x.toLowerCase() === url.toLowerCase());

  if (urlCheck) validationToggle = true;

  return validationToggle;
}

async function handleError(error: any) {
  if (error.message === 'Network Error') {
    toast.error('Unable to connect to the Server.', { id: 'network-error' });

    return Promise.reject(error);
  }

  let message: string | undefined;

  if (error.response?.data && 'error' in error.response.data) {
    message = error.response?.data.error.detail;
  } else {
    message = error.message;
  }

  if (error.response?.data?.error?.type === 'InvalidToken') {
    useAuthStore.getState().removeTokens();
  }

  toast.error(message ?? 'There was an unexpected error loading the data.', { id: 'http-service' });

  return Promise.reject(error);
}

export const axiosInstance = axios.create({
  baseURL: process.env.NEXT_PUBLIC_API_URL,
});

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const status = error.response?.status;

    if (!status) return await handleError(error);

    if (!validateRouteCheck(window.location.pathname) && unAuthorizedStatus.includes(status)) {
      if (error.response.data) {
        const response =
          error.request.responseType === 'arraybuffer'
            ? (JSON.parse(Buffer.from(error.response.data).toString('utf-8')) as GenericErrorResponse).error.detail
            : error.response.data.error.detail;

        toast.error(response, { id: 'http-service' });
      } else {
        toast.error('You are unauthorized to access this resource.', { id: 'http-service' });
      }

      await useAuthStore.getState().getToken(false);
    }

    return await handleError(error);
  }
);

abstract class HttpService {
  abstract url: string;

  protected async getBase<T>(url: string, config: RawAxiosRequestConfig = {}): Promise<AxiosResponse<T>> {
    return await axiosInstance.get<T>(url, await this.getConfig(url, config));
  }

  protected async postBase<T>(url: string, data: any, config: RawAxiosRequestConfig = {}): Promise<AxiosResponse<T>> {
    return await axiosInstance.post<T>(url, data, await this.getConfig(url, config));
  }

  protected async putBase<T>(url: string, data: any, config: RawAxiosRequestConfig = {}): Promise<AxiosResponse<T>> {
    return await axiosInstance.put<T>(url, data, await this.getConfig(url, config));
  }

  protected async deleteBase<T>(url: string, config: RawAxiosRequestConfig = {}): Promise<AxiosResponse<T>> {
    return await axiosInstance.delete<T>(url, await this.getConfig(url, config));
  }

  private async getHeaders(url: string) {
    if (validateApiCheck(url)) return {};

    const accessToken = await useAuthStore.getState().getToken();

    if (!accessToken) return {};

    return {
      Authorization: `Bearer ${accessToken.token}`,
    };
  }

  private async getConfig(url: string, config: RawAxiosRequestConfig = {}) {
    return {
      headers: await this.getHeaders(url),
      paramsSerializer: {
        indexes: true, // 'a[0]=b&a[1]=c'
      },
      ...config,
    };
  }
}

export default HttpService;

import { VerticalNavItemsType } from '@core/layouts/types';
import permissions from '@/configs/permissions';

import {
  MdOutlineDashboard,
  MdOutlineRequestQuote,
  MdOutlineCategory,
  MdOutlineQrCodeScanner,
  MdOutlineTune,
  MdOutlinePeople,
  MdOutlinePersonSearch,
} from 'react-icons/md';
import { TbReportAnalytics } from 'react-icons/tb';
import { LuFileCog } from 'react-icons/lu';
import { BiMoneyWithdraw } from 'react-icons/bi';

export default function navigation(): VerticalNavItemsType {
  return [
    {
      sectionTitle: 'General',
      resource: 'any',
    },
    {
      title: 'Dashboard',
      path: '/',
      icon: MdOutlineDashboard,
      resource: 'any',
    },
    {
      title: 'Sales',
      icon: BiMoneyWithdraw,
      path: '/sales',
      resource: [
        permissions.sales.quote,
        permissions.sales.order,
        permissions.sales.project,
        permissions.sales.customer,
      ],
      children: [
        {
          title: 'Quotes',
          path: '/sales/quotes',
          resource: permissions.sales.quote,
        },
        {
          title: 'Orders',
          path: '/sales/orders',
          resource: permissions.sales.order,
        },
        {
          title: 'Projects',
          path: '/sales/projects',
          resource: permissions.sales.project,
        },
        {
          title: 'Customers',
          path: '/sales/customers',
          resource: permissions.sales.customer,
        },
      ],
    },
    {
      title: 'Production',
      icon: LuFileCog,
      path: '/production',
      resource: [permissions.production.workOrder],
      children: [
        {
          title: 'Work Orders',
          path: '/production/work-orders',
          resource: permissions.production.workOrder,
        },
      ],
    },
    {
      title: 'Accounting',
      icon: MdOutlineRequestQuote,
      path: '/accounting',
      resource: permissions.accounting.bill,
      children: [
        {
          title: 'Bills',
          path: '/accounting/bills',
          resource: permissions.accounting.bill,
        },
      ],
    },
    {
      title: 'Inventory',
      icon: MdOutlineCategory,
      path: '/inventory',
      resource: [
        permissions.inventory.dashboard,
        permissions.inventory.calendar,
        permissions.inventory.item,
        permissions.inventory.bundle,
        permissions.inventory.itemTransfer,
        permissions.inventory.purchaseOrder,
        permissions.inventory.vendor,
      ],
      children: [
        {
          title: 'Dashboard',
          path: '/inventory/dashboard',
          resource: permissions.inventory.dashboard,
        },
        {
          title: 'Calendar',
          path: '/inventory/calendar',
          resource: permissions.inventory.calendar,
        },
        {
          title: 'Items',
          path: '/inventory/items',
          resource: permissions.inventory.item,
        },
        {
          title: 'Item Transfers',
          path: '/inventory/item-transfers',
          resource: permissions.inventory.itemTransfer,
        },
        {
          title: 'Bundles',
          path: '/inventory/bundles',
          resource: permissions.inventory.bundle,
        },
        {
          title: 'Purchase Orders',
          path: '/inventory/purchase-orders',
          resource: permissions.inventory.purchaseOrder,
        },
        {
          title: 'Vendors',
          path: '/inventory/vendors',
          resource: permissions.inventory.vendor,
        },
      ],
    },
    {
      title: 'Scanners',
      icon: MdOutlineQrCodeScanner,
      path: '/scanners',
      resource: [permissions.inventory.itemAdjustment, permissions.inventory.itemTransfer],
      children: [
        {
          title: 'Item Adjustment',
          path: '/scanners/item-adjustment',
          resource: permissions.inventory.itemAdjustment,
        },
        {
          title: 'Item Transfer',
          path: '/scanners/item-transfer',
          resource: permissions.inventory.itemTransfer,
        },
        {
          title: 'Bundle',
          path: '/scanners/inventory/bundle',
          resource: permissions.inventory.bundle,
        },
        {
          title: 'Receive Bundle',
          path: '/scanners/receive-bundle',
          resource: permissions.inventory.bundle,
        },
      ],
    },
    {
      title: 'Legacy Scanners',
      icon: MdOutlineQrCodeScanner,
      path: '/legacy-scanners',
      resource: [
        permissions.legacy.bundle,
        permissions.legacy.process.released,
        permissions.legacy.process.cutting,
        permissions.legacy.process.punching,
        permissions.legacy.process.shipping,
        permissions.legacy.process.assembling,
        permissions.legacy.process.screen,
        permissions.legacy.process.cnc,
        permissions.legacy.process.decoration,
        permissions.legacy.process.glazing,
        permissions.legacy.process.colonial,
        permissions.legacy.process.receive,
        permissions.legacy.glasses.locateGlass,
        permissions.legacy.glasses.takeGlass,
        permissions.legacy.glasses.transferGlass,
        permissions.legacy.glasses.receiveGlass,
        permissions.legacy.glasses.movePallet,
        permissions.legacy.process.delivery,
      ],
      children: [
        {
          title: 'Bundle',
          path: '/legacy-scanners/bundle',
          resource: permissions.legacy.bundle,
        },
        {
          title: 'Glasses',
          path: '/legacy-scanners/glasses',
          resource: [
            permissions.legacy.glasses.locateGlass,
            permissions.legacy.glasses.takeGlass,
            permissions.legacy.glasses.transferGlass,
            permissions.legacy.glasses.receiveGlass,
            permissions.legacy.glasses.movePallet,
          ],
        },
        {
          title: 'Statuses',
          path: '/legacy-scanners/statuses',
          resource: [
            permissions.legacy.process.released,
            permissions.legacy.process.cutting,
            permissions.legacy.process.punching,
            permissions.legacy.process.assembling,
            permissions.legacy.process.screen,
            permissions.legacy.process.cnc,
            permissions.legacy.process.decoration,
            permissions.legacy.process.glazing,
            permissions.legacy.process.colonial,
          ],
        },
        {
          title: 'Decoration',
          path: '/legacy-scanners/decoration',
          resource: permissions.legacy.process.decoration,
        },
        {
          title: 'Receive',
          path: '/legacy-scanners/receive',
          resource: permissions.legacy.process.receive,
        },
        {
          title: 'Shipping',
          path: '/legacy-scanners/shipping',
          resource: permissions.legacy.process.shipping,
        },
        {
          title: 'Delivery',
          path: '/legacy-scanners/delivery',
          resource: permissions.legacy.process.delivery,
        },
      ],
    },
    {
      title: 'Reports',
      icon: TbReportAnalytics,
      path: '/reports',
      resource: permissions.inventory.item,
    },
    {
      sectionTitle: 'Settings',
      resource: [
        permissions.accounting.tax,
        permissions.accounting.qbItem,
        permissions.accounting.qbServer,
        permissions.accounting.qbJob,
      ],
    },
    {
      title: 'Accounting',
      icon: MdOutlineRequestQuote,
      path: '/settings/accounting',
      resource: [
        permissions.accounting.tax,
        permissions.accounting.qbItem,
        permissions.accounting.qbServer,
        permissions.accounting.qbJob,
      ],
      children: [
        {
          title: 'Taxes',
          path: '/settings/accounting/taxes',
          resource: permissions.accounting.tax,
        },
        {
          title: 'Quickbooks',
          path: '/settings/accounting/quickbooks',
          resource: [permissions.accounting.qbItem, permissions.accounting.qbServer, permissions.accounting.qbJob],
          children: [
            {
              title: 'Items',
              path: '/settings/accounting/quickbooks/items',
              resource: permissions.accounting.qbItem,
            },
            {
              title: 'Servers',
              path: '/settings/accounting/quickbooks/servers',
              resource: permissions.accounting.qbServer,
            },
            {
              title: 'Jobs',
              path: '/settings/accounting/quickbooks/jobs',
              resource: permissions.accounting.qbJob,
            },
          ],
        },
      ],
    },
    {
      title: 'Inventory',
      icon: MdOutlineCategory,
      path: '/settings/inventory',
      resource: [
        permissions.inventory.attribute,
        permissions.inventory.itemColor,
        permissions.inventory.itemGroup,
        permissions.inventory.itemUnit,
        permissions.inventory.warehouse,
        permissions.inventory.location,
        permissions.inventory.rack,
      ],
      children: [
        {
          title: 'Attributes',
          path: '/settings/inventory/attributes',
          resource: permissions.inventory.attribute,
        },
        {
          title: 'Colors',
          path: '/settings/inventory/colors',
          resource: permissions.inventory.itemColor,
        },
        {
          title: 'Groups',
          path: '/settings/inventory/groups',
          resource: permissions.inventory.itemGroup,
        },
        {
          title: 'Units',
          path: '/settings/inventory/units',
          resource: permissions.inventory.itemUnit,
        },
        {
          title: 'Warehouses',
          path: '/settings/inventory/warehouses',
          resource: permissions.inventory.warehouse,
        },
        {
          title: 'Locations',
          path: '/settings/inventory/locations',
          resource: permissions.inventory.location,
        },
        {
          title: 'Racks',
          path: '/settings/inventory/racks',
          resource: permissions.inventory.rack,
        },
      ],
    },
    {
      title: 'Setup',
      icon: MdOutlineTune,
      path: '/setup',
      resource: [
        permissions.setup.item,
        permissions.setup.grid,
        permissions.setup.frame,
        permissions.setup.glass,
        permissions.setup.product,
      ],
      children: [
        {
          title: 'Items',
          path: '/setup/items',
          resource: permissions.setup.item,
        },
        {
          title: 'Grids',
          path: '/setup/grids',
          resource: permissions.setup.grid,
        },
        {
          title: 'Frames',
          path: '/setup/frames',
          resource: permissions.setup.frame,
          children: [
            {
              title: 'Colors',
              path: '/setup/frames/colors',
              resource: permissions.setup.frame,
            },
          ],
        },
        {
          title: 'Glass',
          path: '/setup/glass',
          resource: permissions.setup.glass,
          children: [
            {
              title: 'Colors',
              path: '/setup/glass/colors',
              resource: permissions.setup.glass,
            },
            {
              title: 'Components',
              path: '/setup/glass/components',
              resource: permissions.setup.glass,
            },
            {
              title: 'Compositions',
              path: '/setup/glass/compositions',
              resource: permissions.setup.glass,
            },
          ],
        },
        {
          title: 'Products',
          path: '/setup/products',
          resource: permissions.setup.product,
        },
      ],
    },
    {
      sectionTitle: 'Management',
      resource: [permissions.account.user, permissions.audit.log],
    },
    {
      title: 'Accounts',
      icon: MdOutlinePeople,
      path: '/accounts',
      resource: [permissions.account.user, permissions.account.role],
      children: [
        {
          title: 'Users',
          path: '/accounts/users',
          resource: permissions.account.user,
        },
        {
          title: 'Roles',
          path: '/accounts/roles',
          resource: permissions.account.role,
        },
      ],
    },
    {
      title: 'Audits',
      icon: MdOutlinePersonSearch,
      path: '/audits',
      resource: permissions.audit.log,
    },
  ];
}

// ** React Imports
import { Fragment, useEffect, ReactNode } from 'react';

// ** MUI Imports
import { Direction as MuiDirection } from '@mui/material';

// ** Emotion Imports
import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';

// ** RTL Plugin
import stylisRTLPlugin from 'stylis-plugin-rtl';

interface DirectionProps {
  children: ReactNode;
  direction: MuiDirection;
}

const styleCache = () =>
  createCache({
    key: 'rtl',
    prepend: true,
    stylisPlugins: [stylisRTLPlugin],
  });

export default function Direction(props: DirectionProps) {
  const { children, direction } = props;

  useEffect(() => {
    document.dir = direction;
  }, [direction]);

  if (direction === 'rtl') {
    return <CacheProvider value={styleCache()}>{children}</CacheProvider>;
  }

  return <Fragment>{children}</Fragment>;
}

import { Fragment, ElementType, useState } from 'react';

import { MdOutlineDelete } from 'react-icons/md';

import { toast } from 'sonner';

import TooltipAction from '@/components/actions/shared/TooltipAction';
import ConfirmDialog from '@/components/dialogs/ConfirmDialog';
import Permissions, { PermissionResource } from '@/components/utilities/Permissions';

import actionService from '@/services/http/ActionService';
import { useMutation } from '@tanstack/react-query';

interface IDeleteActionProps {
  resource: PermissionResource;
  title?: string;
  description?: string;
  id: number | string;
  url: string;
  urlDeleteSuffix?: string;
  onSuccess: () => void;
  onError?: () => void;

  // ActionTooltip
  tooltip?: string;
  icon?: ElementType;
  iconSize?: number;
  displayAsMenu?: boolean;
  onClick?: () => void;
}

export default function DeleteAction(props: IDeleteActionProps) {
  const {
    resource,
    title = 'Delete',
    description = 'Are you sure you want to delete this record?',
    url,
    id,
    onSuccess,
    onError,
    onClick,
    tooltip = 'Delete',
    icon: Icon = MdOutlineDelete,
    iconSize,
    displayAsMenu,
    urlDeleteSuffix,
  } = props;

  const [open, setOpen] = useState(false);

  const { mutateAsync, isPending } = useMutation({ mutationFn: actionService.deleteAction });

  async function handleOnConfirm() {
    try {
      await mutateAsync({ url, id, urlDeleteSuffix });

      setOpen(false);
      onSuccess();
      toast.success('The record was successfully deleted.');
    } catch (error) {
      setOpen(false);
      onError?.();
    }
  }

  function handleTooltipClick() {
    setOpen(true);
    onClick?.();
  }

  function renderIcon() {
    return (
      <Permissions resource={resource} action='Delete'>
        <TooltipAction
          title={tooltip}
          icon={Icon}
          iconSize={iconSize}
          onClick={handleTooltipClick}
          displayAsMenu={displayAsMenu}
        />
      </Permissions>
    );
  }

  if (!open) return renderIcon();

  return (
    <Fragment>
      {renderIcon()}
      <ConfirmDialog
        title={title}
        loading={isPending}
        open={open}
        message={description}
        onConfirm={handleOnConfirm}
        onCancel={() => setOpen(false)}
      />
    </Fragment>
  );
}

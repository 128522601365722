import { ElementType, Dispatch, SetStateAction, createElement, Fragment, useState } from 'react';

import { useMutation } from '@tanstack/react-query';
import actionService from '@/services/http/ActionService';
import legacyActionService from '@/services/http/legacy/LegacyActionService';

import { toast } from 'sonner';

import { useForm } from 'react-hook-form';
import { FieldValues } from 'react-hook-form/dist/types/fields';
import { zodResolver } from '@hookform/resolvers/zod';

import { ZodSchema } from 'zod';

import { FormAction, IForm, IFormExtras } from '@/components/forms/Form';
import ConfirmDialog from '@/components/dialogs/ConfirmDialog';

import FormDialog, { IFormDialogProps } from '@/components/dialogs/FormDialog';

import { removePropertiesFromObject } from '@/utilities/removePropertiesFromObject';

export interface IAddActionDialogProps<TValue extends FieldValues, TForm>
  extends Omit<
    IFormDialogProps<TValue>,
    'title' | 'open' | 'loading' | 'onSubmit' | 'onClose' | 'children' | 'formContext'
  > {
  title?: string;
  url: string;
  form: ElementType<TForm>;
  validationSchema?: ZodSchema<TValue>;
  onSuccess?: (data: any) => void;
  onError?: () => void;
  onCancel?: () => void;
  onBeforeSubmit?: (url: string, values: TValue, action: FormAction) => { url: string; values: any };
  onRequestNewProps?: () => void;
  removeUndefined?: boolean;
  removeNull?: boolean;
  removeEmpty?: boolean;
  propData?: TForm extends IForm<unknown, infer A, unknown> ? A & IFormExtras<TValue> : never;
  setOpen: Dispatch<SetStateAction<boolean>>;
  useRubyApi?: boolean;
  successMessage?: string;
  enableConfirm?: boolean;
  descriptionConfirm?: string;
}

export default function AddActionDialog<TValue extends FieldValues, TForm extends IForm<unknown>>(
  props: IAddActionDialogProps<TValue, TForm>
) {
  const {
    title = 'Add',
    url,
    form,
    validationSchema,
    validationMode = 'onChange',
    defaultValues,
    maxWidth = 'sm',
    onSuccess,
    onError,
    onCancel,
    onBeforeSubmit,
    onRequestNewProps,
    removeUndefined = false,
    removeNull = false,
    removeEmpty = false,
    propData,
    setOpen,
    useRubyApi = false,
    successMessage = 'The record was successfully added.',
    enableConfirm = false,
    descriptionConfirm,
    ...rest
  } = props;

  const [openConfirm, setOpenConfirm] = useState(false);
  const [formData, setFormData] = useState<TValue | undefined>(undefined);

  const { mutateAsync: mutateApiAsync, isPending: isApiPending } = useMutation({
    mutationFn: actionService.addAction,
  });
  const { mutateAsync: mutateRubyAsync, isPending: isRubyPending } = useMutation({
    mutationFn: legacyActionService.addAction,
  });

  const formMethods = useForm<TValue>({
    mode: validationMode,
    defaultValues: defaultValues,
    resolver: validationSchema ? zodResolver(validationSchema) : undefined,
  });

  const { reset } = formMethods;

  async function handleOnSubmit(data?: TValue) {
    if (!data) {
      throw new Error('data cannot be undefined');
    }

    let newUrl = url;
    let newData = data;

    if (onBeforeSubmit) {
      const values = onBeforeSubmit(url, data, 'add');

      newUrl = values.url;
      newData = values.values;
    }

    newData = removePropertiesFromObject(newData, removeUndefined, removeNull, removeEmpty, true);

    try {
      const result = useRubyApi
        ? await mutateRubyAsync({ url: newUrl, data: newData })
        : await mutateApiAsync({ url: newUrl, data: newData });

      toast.success(successMessage);

      reset();
      setOpen(false);

      onSuccess?.(result);
    } catch (error) {
      onError?.();
    }
  }

  async function handleConfirmation(data: any) {
    setOpenConfirm(true);
    setFormData(data);
  }

  function handleOnCancel() {
    setOpen(false);
    reset();
    onCancel?.();
  }

  function renderForm() {
    const newContext: TForm = {
      context: { action: 'add' },
      propData: propData ?? {},
      onRequestNewProps,
    } as TForm;

    return createElement(form, { ...newContext }, null);
  }

  return (
    <Fragment>
      {enableConfirm && (
        <ConfirmDialog
          message={descriptionConfirm}
          loading={false}
          open={openConfirm}
          onConfirm={() => handleOnSubmit(formData)}
          onCancel={() => setOpenConfirm(false)}
        />
      )}
      <FormDialog
        {...rest}
        title={title}
        loading={useRubyApi ? isRubyPending : isApiPending}
        open={true}
        onSubmit={enableConfirm ? handleConfirmation : handleOnSubmit}
        onClose={handleOnCancel}
        maxWidth={maxWidth}
        validationSchema={validationSchema}
        formContext={formMethods}
      >
        {renderForm()}
      </FormDialog>
    </Fragment>
  );
}

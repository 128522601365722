import { CSSProperties, ReactNode } from 'react';

import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Backdrop from '@mui/material/Backdrop';
import LinearProgress from '@mui/material/LinearProgress';
import CircularProgress from '@mui/material/CircularProgress';

type Variant = 'linear' | 'circular' | 'table' | 'form' | 'input' | 'photos' | 'backdrop';

interface LoadingProps {
  variant?: Variant;
  style?: CSSProperties;
  children?: never;
}

interface LoadingWithChildrenProps {
  variant?: Variant;
  style?: CSSProperties;
  isLoading: boolean;
  children: ReactNode;
}

const Loading = (props: LoadingProps | LoadingWithChildrenProps) => {
  const { variant = 'linear', style } = props;

  if ('isLoading' in props && 'children' in props && !props.isLoading) {
    return <Box style={style}>{props.children}</Box>;
  }

  switch (variant) {
    case 'circular':
      return (
        <Backdrop open={true} sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} style={style}>
          <CircularProgress size={60} />
        </Backdrop>
      );
    case 'linear':
      return <LinearProgress style={style} sx={{ height: 5 }} />;
    case 'table':
      return (
        <Stack spacing={0} style={style}>
          <Skeleton variant='text' height={80} />
          <Skeleton variant='text' height={50} />
          <Skeleton variant='text' height={50} />
          <Skeleton variant='text' height={50} />
        </Stack>
      );
    case 'form':
      return (
        <Stack spacing={0} style={style}>
          <Skeleton variant='text' height={70} />
          <Skeleton variant='text' height={70} />
          <Skeleton variant='text' height={70} />
          <Skeleton variant='text' height={70} />
          <Skeleton variant='text' height={70} />
        </Stack>
      );
    case 'input':
      return <Skeleton variant='text' height={70} style={style} />;
    case 'photos':
      return (
        <Box style={style}>
          <Stack spacing={1} direction='row'>
            <Skeleton variant='rectangular' width='33.33%' height={100} />
            <Skeleton variant='rectangular' width='33.33%' height={100} />
            <Skeleton variant='rectangular' width='33.33%' height={100} />
          </Stack>
          <Stack spacing={1} direction='row' sx={{ marginTop: 1 }}>
            <Skeleton variant='rectangular' width='33.33%' height={100} />
            <Skeleton variant='rectangular' width='33.33%' height={100} />
            <Skeleton variant='rectangular' width='33.33%' height={100} />
          </Stack>
        </Box>
      );
    case 'backdrop': {
      return (
        <Box sx={{ position: 'relative' }}>
          {'children' in props ? props.children : null}
          <Backdrop
            style={style}
            sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, position: 'absolute' }}
            open={'isLoading' in props ? props.isLoading : false}
          >
            <CircularProgress />
          </Backdrop>
        </Box>
      );
    }
    default:
      return null;
  }
};

export default Loading;

import Stack from '@mui/material/Stack';

import { z } from 'zod';

import TextInput from '@/components/forms/inputs/TextInput';

export const validationSchema = z.object({
  firstName: z.string().min(1, 'First Name is required'),
  lastName: z.string().min(1, 'Last Name is required'),
});

export type FormValues = z.infer<typeof validationSchema>;

const ProfileInfoForm = () => {
  return (
    <Stack spacing={1}>
      <TextInput name='firstName' onKeyDown={(e) => e.stopPropagation()} required />
      <TextInput name='lastName' onKeyDown={(e) => e.stopPropagation()} required />
    </Stack>
  );
};

export default ProfileInfoForm;

import { Fragment, ElementType, useState, ReactElement, cloneElement } from 'react';

import { MdOutlineLaunch } from 'react-icons/md';

import ContentDialog, { IContentDialogProps } from '@/components/dialogs/ContentDialog';
import TooltipAction from '@/components/actions/shared/TooltipAction';

import Permissions, { PermissionResource, PermissionAction } from '@/components/utilities/Permissions';

interface IDialogActionProps extends Omit<IContentDialogProps, 'open'> {
  open?: boolean;
  fullScreen?: boolean;
  clickContent?: ReactElement;
  resource: PermissionResource;
  action?: PermissionAction;

  // ActionTooltip
  tooltip?: string;
  icon?: ElementType;
  iconSize?: number;
  displayAsMenu?: boolean;
  onClick?: () => void;
}

export default function DialogAction(props: IDialogActionProps) {
  const {
    maxWidth = 'sm',
    open: openProp,
    onClose,
    onClick,
    clickContent,
    children,
    resource,
    action,
    tooltip = 'Open',
    icon: Icon = MdOutlineLaunch,
    iconSize,
    displayAsMenu,
    ...rest
  } = props;

  const [open, setOpen] = useState(false);

  function openDialog() {
    if (!openProp) setOpen(true);
    onClick?.();
  }

  function handleOnClose() {
    if (!openProp) setOpen(false);
    onClose?.();
  }

  function renderIcon() {
    if (clickContent) {
      const content = cloneElement(clickContent, { onClick: openDialog });

      return (
        <Permissions resource={resource} action={action}>
          {content}
        </Permissions>
      );
    }

    return (
      <Permissions resource={resource} action={action}>
        <TooltipAction
          title={tooltip}
          icon={Icon}
          iconSize={iconSize}
          onClick={openDialog}
          displayAsMenu={displayAsMenu}
        />
      </Permissions>
    );
  }

  if (!(openProp ?? open)) return renderIcon();

  return (
    <Fragment>
      {renderIcon()}
      <ContentDialog
        open={openProp !== undefined ? openProp : open}
        onClose={handleOnClose}
        maxWidth={maxWidth}
        {...rest}
      >
        {children}
      </ContentDialog>
    </Fragment>
  );
}

import { FormHTMLAttributes, ReactNode } from 'react';

import { FormProvider, useForm, SubmitHandler, UseFormReturn } from 'react-hook-form';
import { DefaultValues } from 'react-hook-form/dist/types/form';
import { FieldValues } from 'react-hook-form/dist/types/fields';
import { zodResolver } from '@hookform/resolvers/zod';

import { ZodSchema } from 'zod';

import Form from './Form';

export type ValidationMode = 'onBlur' | 'onChange' | 'onSubmit' | 'onTouched' | 'all';

export interface FormContainerCoreProps<T extends FieldValues> {
  formId?: string;
  defaultValues?: DefaultValues<T>;
  onSubmit: SubmitHandler<T>;
  validationSchema?: ZodSchema<T>;
  validationMode?: ValidationMode;
  FormProps?: FormHTMLAttributes<HTMLFormElement>;
  devTools?: boolean;
  shouldUnregister?: boolean;
  children: ReactNode;
}

export interface FormContainerProps<T extends FieldValues> extends FormContainerCoreProps<T> {
  formContext?: UseFormReturn<T>;
}

function FormContainerCore<T extends FieldValues>(props: FormContainerCoreProps<T>) {
  const {
    defaultValues,
    onSubmit,
    validationSchema,
    validationMode,
    FormProps,
    devTools,
    formId,
    shouldUnregister = false,
    children,
  } = props;

  const methods = useForm<T>({
    shouldUnregister: shouldUnregister,
    defaultValues: defaultValues,
    mode: validationMode,
    resolver: validationSchema ? zodResolver(validationSchema) : undefined,
  });

  const { handleSubmit, control } = methods;

  return (
    <FormProvider {...methods}>
      <Form id={formId} control={control} devTools={devTools} onSubmit={handleSubmit(onSubmit)} {...FormProps}>
        {children}
      </Form>
    </FormProvider>
  );
}

export default function FormContainer<T extends FieldValues>(props: FormContainerProps<T>) {
  const {
    defaultValues,
    onSubmit,
    validationMode = 'onChange',
    FormProps,
    devTools,
    formId,
    formContext,
    validationSchema,
    shouldUnregister,
    children,
  } = props;

  if (formContext) {
    return (
      <FormProvider {...formContext}>
        <Form
          id={formId}
          control={formContext.control}
          devTools={devTools}
          onSubmit={formContext.handleSubmit(onSubmit)}
          {...FormProps}
        >
          {children}
        </Form>
      </FormProvider>
    );
  }

  return (
    <FormContainerCore
      onSubmit={onSubmit}
      FormProps={FormProps}
      devTools={devTools}
      validationSchema={validationSchema}
      validationMode={validationMode}
      defaultValues={defaultValues}
      shouldUnregister={shouldUnregister}
    >
      {children}
    </FormContainerCore>
  );
}

// ** MUI Theme Provider
import { deepmerge } from '@mui/utils';
import { PaletteMode, ThemeOptions } from '@mui/material';

// ** User Theme Options
import UserThemeOptions from '@/layouts/UserThemeOptions';

// ** Type Import
import { Settings } from '@core/context/settingsContext';

// ** Theme Override Imports
import palette from './palette';
import spacing from './spacing';
import shadows from './shadows';
import overrides from './overrides';
import typography from './typography';
import breakpoints from './breakpoints';

const themeOptions = (settings: Settings, overrideMode: PaletteMode): ThemeOptions => {
  // ** Vars
  const { mode, direction, themeColor } = settings;

  // ** Create New object before removing user component overrides and typography objects from userThemeOptions
  const userThemeConfig: ThemeOptions = Object.assign({}, UserThemeOptions());

  const mergedThemeConfig: ThemeOptions = deepmerge(
    {
      breakpoints: breakpoints(),
      direction,
      components: overrides(settings),
      palette: palette(mode === 'semi-dark' ? overrideMode : mode, settings),
      ...spacing,
      shape: {
        borderRadius: 6,
      },
      mixins: {
        toolbar: {
          minHeight: 64,
        },
      },
      shadows: shadows(mode === 'semi-dark' ? overrideMode : mode),
      typography,
    },
    userThemeConfig
  );

  return deepmerge(mergedThemeConfig, {
    palette: {
      primary: {
        ...(mergedThemeConfig.palette
          ? mergedThemeConfig.palette[themeColor]
          : palette(mode === 'semi-dark' ? overrideMode : mode, settings).primary),
      },
    },
  });
};

export default themeOptions;

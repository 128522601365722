import dayjs from 'dayjs';

class Formatters {
  toDateTime(value: string | Date, format: 'MM/DD/YYYY' | 'MM/DD/YYYY hh:mm A' = 'MM/DD/YYYY') {
    return dayjs(value).format(format);
  }

  // (000) 000-0000
  toPhoneNumber(value: string) {
    const cleaned = ('' + value).replace(/\D/g, '');

    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

    if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3];
    }

    return null;
  }

  // $10.20
  toDollar(value: number, decimals = 2) {
    const converter = Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: decimals,
    });

    return converter.format(value);
  }

  // 1/4"
  toFraction(value: number, suffix = '"'): string {
    const precision = 0.0313; // 1/32"
    const fractions = new Map<number, string>([
      [0.0, ''],
      [0.0625, '1/16'],
      [0.125, '1/8'],
      [0.1875, '3/16'],
      [0.25, '1/4'],
      [0.3125, '5/16'],
      [0.375, '3/8'],
      [0.4375, '7/16'],
      [0.5, '1/2'],
      [0.5625, '9/16'],
      [0.625, '5/8'],
      [0.6875, '11/16'],
      [0.75, '3/4'],
      [0.8125, '13/16'],
      [0.875, '7/8'],
      [0.9375, '15/16'],
    ]);

    let wholeNumber = Math.trunc(value);
    const fractionPart = Math.round((value - wholeNumber) * 10000) / 10000;
    let strFraction = '';

    for (const [fractionValue, fractionText] of fractions) {
      if (fractionPart <= fractionValue + precision) {
        strFraction = fractionText;
        break;
      }
    }

    if (!strFraction && fractionPart > 0) {
      strFraction = '';
      wholeNumber += 1;
    }

    const fraction = strFraction === '' ? `${wholeNumber}` : `${wholeNumber} ${strFraction}`;

    return `${fraction}${suffix}`;
  }

  // String
  toTitleCase(str: string) {
    return str
      .replace(/([A-Z])/g, ' $1')
      .replace(/^./, function (str) {
        return str.toUpperCase();
      })
      .trim();
  }

  toSentenceCase(text: string) {
    text = text
      .replace(/^_/, '')
      .replace(/([A-Z])/g, ' $1')
      .trim();

    return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
  }
}

export default new Formatters();

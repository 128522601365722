// ** This file has been modified - YC

// ** Next Import
import Link from 'next/link';

// ** MUI Imports
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Box, { BoxProps } from '@mui/material/Box';
import { styled, useTheme } from '@mui/material/styles';

// ** Type Import
import { LayoutProps } from '@core/layouts/types';

// ** Icons Imports
import { BiChevronLeft } from 'react-icons/bi';

// ** Extras
import Image from 'next/image';

interface Props {
  navHover: boolean;
  collapsedNavWidth: number;
  hidden: LayoutProps['hidden'];
  navigationBorderWidth: number;
  toggleNavVisibility: () => void;
  settings: LayoutProps['settings'];
  saveSettings: LayoutProps['saveSettings'];
  navMenuBranding?: LayoutProps['verticalLayoutProps']['navMenu']['branding'];
  menuLockedIcon?: LayoutProps['verticalLayoutProps']['navMenu']['lockedIcon'];
  menuUnlockedIcon?: LayoutProps['verticalLayoutProps']['navMenu']['unlockedIcon'];
}

// ** Styled Components
const MenuHeaderWrapper = styled(Box)<BoxProps>(({ theme }) => ({
  display: 'flex',
  overflow: 'hidden',
  alignItems: 'center',
  marginTop: theme.spacing(3),
  paddingRight: theme.spacing(5),
  justifyContent: 'space-between',
  transition: 'padding .25s ease-in-out',
  minHeight: theme.mixins.toolbar.minHeight,
}));

const LinkStyled = styled(Link)({
  display: 'flex',
  alignItems: 'center',
  textDecoration: 'none',
});

const VerticalNavHeader = (props: Props) => {
  // ** Props
  const {
    hidden,
    navHover,
    settings,
    saveSettings,
    collapsedNavWidth,
    toggleNavVisibility,
    navigationBorderWidth,
    menuLockedIcon: userMenuLockedIcon,
    navMenuBranding: userNavMenuBranding,
    menuUnlockedIcon: userMenuUnlockedIcon,
  } = props;

  // ** Hooks & Vars
  const theme = useTheme();
  const { skin, direction, navCollapsed } = settings;

  const menuCollapsedStyles = navCollapsed && !navHover ? { opacity: 0 } : { opacity: 1 };

  const handleButtonClick = () => {
    if (hidden) {
      toggleNavVisibility();
    } else {
      saveSettings({ ...settings, navCollapsed: !navCollapsed });
    }
  };

  const menuHeaderPaddingLeft = () => {
    if (navCollapsed && !navHover) {
      if (userNavMenuBranding) {
        return 0;
      } else {
        return (collapsedNavWidth - navigationBorderWidth - 25) / 10;
      }
    } else {
      return 6;
    }
  };

  const svgRotationDeg = () => {
    if (navCollapsed) {
      if (direction === 'rtl') {
        if (navHover) {
          return 0;
        } else {
          return 180;
        }
      } else {
        if (navHover) {
          return 180;
        } else {
          return 0;
        }
      }
    } else {
      if (direction === 'rtl') {
        return 180;
      } else {
        return 0;
      }
    }
  };

  return (
    <MenuHeaderWrapper className='nav-header' sx={{ pl: menuHeaderPaddingLeft() }}>
      {userNavMenuBranding ? (
        userNavMenuBranding(props)
      ) : (
        <LinkStyled href='/' passHref>
          <Image src={'/images/logos/logo.svg'} alt='logo' width={45} height={45} />
          <Typography
            variant='h5'
            sx={{
              lineHeight: 0,
              fontWeight: 700,
              ...menuCollapsedStyles,
              letterSpacing: '-0.45px',
              textTransform: 'lowercase',
              fontSize: '1.75rem !important',
              ...(navCollapsed && !navHover ? {} : { ml: 1 }),
              transition: 'opacity .35s ease-in-out, margin .35s ease-in-out',
            }}
          >
            {settings.mode === 'dark' ? (
              <Image
                src={'/images/logos/text-horizontal-white.svg'}
                alt='logo'
                width={135}
                height={45}
                priority={true}
              />
            ) : (
              <Image src={'/images/logos/text-horizontal.svg'} alt='logo' width={135} height={45} priority={true} />
            )}
          </Typography>
        </LinkStyled>
      )}

      {userMenuLockedIcon === null && userMenuUnlockedIcon === null ? null : (
        <IconButton
          disableRipple
          disableFocusRipple
          onClick={handleButtonClick}
          sx={{
            p: 1.75,
            right: -19,
            position: 'absolute',
            color: 'text.primary',
            '& svg': { color: 'common.white' },
            transition: 'right .25s ease-in-out',
            backgroundColor: hidden ? 'background.paper' : 'customColors.collapseTogglerBg',
            ...(navCollapsed && !navHover && { display: 'none' }),
            ...(!hidden &&
              skin === 'bordered' && {
                '&:before': {
                  zIndex: -1,
                  content: '""',
                  width: '105%',
                  height: '105%',
                  borderRadius: '50%',
                  position: 'absolute',
                  border: `1px solid ${theme.palette.divider}`,
                  clipPath: direction === 'rtl' ? 'circle(71% at 100% 50%)' : 'circle(71% at 0% 50%)',
                },
              }),
          }}
        >
          <Box sx={{ display: 'flex', borderRadius: 5, backgroundColor: 'primary.main' }}>
            {userMenuLockedIcon && userMenuUnlockedIcon ? (
              navCollapsed ? (
                userMenuUnlockedIcon
              ) : (
                userMenuLockedIcon
              )
            ) : (
              <Box
                sx={{
                  display: 'flex',
                  '& svg': {
                    transform: `rotate(${svgRotationDeg()}deg)`,
                    transition: 'transform .25s ease-in-out .35s',
                  },
                }}
              >
                <BiChevronLeft />
              </Box>
            )}
          </Box>
        </IconButton>
      )}
    </MenuHeaderWrapper>
  );
};

export default VerticalNavHeader;

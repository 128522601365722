import { ElementType } from 'react';

import { useRouter } from 'next/router';

import TooltipAction from '@/components/actions/shared/TooltipAction';
import { MdOutlineMenuOpen } from 'react-icons/md';

interface NavigateActionProps {
  url: string;

  // ActionTooltip
  tooltip?: string;
  icon?: ElementType;
  iconSize?: number;
  displayAsMenu?: boolean;
}

const NavigateAction = (props: NavigateActionProps) => {
  const { url, tooltip = 'Open', icon: Icon = MdOutlineMenuOpen, iconSize, displayAsMenu } = props;

  const router = useRouter();

  const handleOnClick = async () => {
    await router.push(url);
  };

  return (
    <TooltipAction
      title={tooltip}
      onClick={handleOnClick}
      icon={Icon}
      iconSize={iconSize}
      displayAsMenu={displayAsMenu}
    />
  );
};

export default NavigateAction;

import { RawAxiosRequestConfig } from 'axios';

import HttpService from '@/services/HttpService';
import { GenericResponse, INameStringResponse, IPagination } from '@/services/http/types';

export interface IUser {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  roles: INameStringResponse[];
  disabled: boolean;
  customer: INameStringResponse;
}

export interface IUserProfile {
  id: string;
  firstName: string;
  lastName: string;
}

interface ILoginResponse {
  accessToken: { expiration: string; token: string };
  refreshToken: { expiration: string; token: string };
  permissions: string[];
}

interface IUpdateUserProfile {
  firstName: string;
  lastName: string;
}

interface IUpdateUserPassword {
  currentPassword: string;
  password: string;
  confirmPassword: string;
}

interface IChangePassword {
  userId: string;
  token: string;
  password: string;
  confirmPassword: string;
}

export interface IRubyCredential {
  credentialId: number;
  email: string;
  password: string;
}

class UserService extends HttpService {
  constructor() {
    super();

    this.inviteUrl = this.inviteUrl.bind(this);
    this.enableUrl = this.enableUrl.bind(this);
    this.disableUrl = this.disableUrl.bind(this);
    this.forgotPassword = this.forgotPassword.bind(this);
    this.changePassword = this.changePassword.bind(this);
    this.updateProfile = this.updateProfile.bind(this);
    this.updatePassword = this.updatePassword.bind(this);
  }

  url = '/v1/Users';

  inviteUrl = (userId: string): string => {
    return `${this.url}/${userId}/Invite`;
  };

  enableUrl = (userId: string): string => {
    return `${this.url}/${userId}/Enable`;
  };

  disableUrl = (userId: string): string => {
    return `${this.url}/${userId}/Disable`;
  };

  async getPaginated(page: number, pageSize: number, searchTerm?: string, filters?: any) {
    const config: RawAxiosRequestConfig = {
      params: {
        page: page,
        pageSize: pageSize,
      },
    };

    if (searchTerm !== '') config.params.searchTerm = searchTerm;

    if (filters) {
      if (filters.email) config.params.email = filters.email;
      if (filters.roles) config.params.roles = filters.roles;
    }

    const response = await this.getBase<IPagination<IUser>>(this.url, config);

    return response.data;
  }

  async forgotPassword(email: string) {
    const response = await this.postBase<GenericResponse>(`${this.url}/ForgotPassword`, { email });

    return response.data;
  }

  async changePassword({ userId, token, password, confirmPassword }: IChangePassword) {
    const response = await this.putBase<GenericResponse>(`${this.url}/${userId}/ChangePassword`, {
      token,
      password,
      confirmPassword,
    });

    return response.data;
  }

  async getProfile() {
    const response = await this.getBase<IUserProfile>(`${this.url}/Profile`);

    return response.data;
  }

  async updateProfile(profileInfo: IUpdateUserProfile) {
    const response = await this.putBase<ILoginResponse>(`${this.url}/Profile`, profileInfo);

    return response.data;
  }

  async updatePassword(profilePassword: IUpdateUserPassword) {
    const response = await this.putBase<GenericResponse>(`${this.url}/Password`, profilePassword);

    return response.data;
  }
}

export default new UserService();

import { Fragment, useState, ElementType } from 'react';

import { FieldValues } from 'react-hook-form/dist/types/fields';

import { MdOutlineDiscFull } from 'react-icons/md';

import { IForm } from '@/components/forms/Form';
import TooltipAction from '@/components/actions/shared/TooltipAction';
import Permissions, { PermissionAction, PermissionResource } from '@/components/utilities/Permissions';

import VoidActionDialog, { IVoidActionDialogProps } from './VoidActionDialog';

interface IVoidActionProps<TValue extends FieldValues, TForm>
  extends Omit<IVoidActionDialogProps<TValue, TForm>, 'setOpen' | 'title'> {
  resource: PermissionResource;
  action?: PermissionAction;
  title?: string;

  // ActionTooltip
  tooltip: string;
  icon?: ElementType;
  iconSize?: number;
  displayAsMenu?: boolean;
  onClick?: () => void;
}

export default function VoidAction<TValue extends FieldValues, TForm extends IForm<unknown>>(
  props: IVoidActionProps<TValue, TForm>
) {
  const {
    resource = 'any',
    action,
    onClick,
    tooltip,
    title = tooltip,
    icon: Icon = MdOutlineDiscFull,
    iconSize,
    displayAsMenu,
    ...rest
  } = props;

  const [open, setOpen] = useState(false);

  function handleOnClick() {
    setOpen(true);
    onClick?.();
  }

  function renderIcon() {
    return (
      <Permissions resource={resource} action={action}>
        <TooltipAction
          title={tooltip}
          onClick={handleOnClick}
          icon={Icon}
          iconSize={iconSize}
          displayAsMenu={displayAsMenu}
        />
      </Permissions>
    );
  }

  if (!open) return renderIcon();

  return (
    <Fragment>
      {renderIcon()}
      {open && <VoidActionDialog {...rest} title={title} setOpen={setOpen} />}
    </Fragment>
  );
}

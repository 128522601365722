import { CSSProperties, ElementType } from 'react';

import Fab from '@mui/material/Fab';
import { styled } from '@mui/material/styles';
import { Badge } from '@mui/material';

interface FabActionProps {
  icon: ElementType;
  bottom: number;
  right?: number;
  left?: number;
  width?: number;
  height?: number;
  iconSize?: number;
  fabColor?: 'secondary' | 'inherit' | 'primary' | 'default' | 'success' | 'error' | 'info' | 'warning';
  onClick: () => void;
  badgeContent?: number;
  position?: 'static' | 'relative' | 'absolute' | 'sticky' | 'fixed';
  badgeColor?: 'secondary' | 'primary' | 'default' | 'success' | 'error' | 'info' | 'warning';
  style?: CSSProperties;
}

const FabAction = (props: FabActionProps) => {
  const {
    iconSize = 40,
    icon: Icon,
    onClick,
    bottom,
    right,
    width = 60,
    height = 60,
    fabColor = 'secondary',
    badgeContent,
    badgeColor = 'secondary',
    style,
  } = props;

  const FabStyle = styled(Fab)({
    position: 'fixed',
    right: right,
    bottom: bottom,
    width: width,
    height: height,
    ...style,
  });

  return (
    <FabStyle color={fabColor} onClick={onClick} sx={{ zIndex: 1 }}>
      <Badge badgeContent={badgeContent} color={badgeColor} max={1000}>
        <Icon style={{ fontSize: iconSize }} />
      </Badge>
    </FabStyle>
  );
};

export default FabAction;

export const removePropertiesFromObject = (
  obj: any,
  removeUndefined = true,
  removeNull = true,
  removeEmpty = true,
  removeUnderScoreName = true
) => {
  for (const propName in obj) {
    if (obj[propName] === undefined && removeUndefined) {
      delete obj[propName];
    }

    if (obj[propName] === null && removeNull) {
      delete obj[propName];
    }

    if (obj[propName] === '' && removeEmpty) {
      delete obj[propName];
    }

    if (propName.startsWith('_') && removeUnderScoreName) {
      delete obj[propName];
    }

    if (Array.isArray(obj[propName])) {
      obj[propName].map((item: any) =>
        removePropertiesFromObject(item, removeUndefined, removeNull, removeEmpty, removeUnderScoreName)
      );
    }
  }

  return obj;
};

// ** This file has been modified - YC

// ** React
import { Fragment } from 'react';

// ** Types
import { NavLink, NavGroup, HorizontalNavItemsType } from '@core/layouts/types';

// ** Custom Navigation Components
import HorizontalNavLink from './HorizontalNavLink';
import HorizontalNavGroup from './HorizontalNavGroup';

import Permissions from '@/components/utilities/Permissions';

interface Props {
  hasParent?: boolean;
  horizontalNavItems?: HorizontalNavItemsType;
}

const resolveComponent = (item: NavGroup | NavLink) => {
  if ((item as NavGroup).children) return HorizontalNavGroup;

  return HorizontalNavLink;
};

const HorizontalNavItems = (props: Props) => {
  const RenderMenuItems = props.horizontalNavItems?.map((item: NavGroup | NavLink, index: number) => {
    const TagName: any = resolveComponent(item);

    return (
      <Permissions key={index} resource={item.resource}>
        <TagName {...props} item={item} />
      </Permissions>
    );
  });

  return <Fragment>{RenderMenuItems}</Fragment>;
};

export default HorizontalNavItems;

// ** This file has been modified - YC

// ** MUI Imports
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

// ** Icons Imports
import { BiMenu } from 'react-icons/bi';

// ** Type Import
import { Settings } from '@core/context/settingsContext';

// ** Components
import ModeToggler from '@core/layouts/components/shared-components/ModeToggler';
import UserDropdown from '@core/layouts/components/shared-components/UserDropdown';
import LanguageDropdown from '@core/layouts/components/shared-components/LanguageDropdown';
import Autocomplete from '@/layouts/components/Autocomplete';

// ** Utils
import formatters from '@/utilities/Formatters';

interface Props {
  hidden: boolean;
  settings: Settings;
  toggleNavVisibility: () => void;
  saveSettings: (values: Settings) => void;
}

export default function AppBarContent(props: Props) {
  // ** Props
  const { hidden, settings, saveSettings, toggleNavVisibility } = props;

  const env = process.env.NEXT_PUBLIC_ENV;

  return (
    <Box sx={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
      <Box className='actions-left' sx={{ mr: 2, display: 'flex', alignItems: 'center' }}>
        {hidden ? (
          <IconButton color='inherit' sx={{ ml: -2.75 }} onClick={toggleNavVisibility}>
            <BiMenu size={28} />
          </IconButton>
        ) : null}
        <Autocomplete hidden={hidden} settings={settings} />
      </Box>
      <Box className='actions-right' sx={{ display: 'flex', alignItems: 'center' }}>
        {env !== 'production' && env !== 'development' && env !== undefined && (
          <Typography color='error' sx={{ marginRight: 4 }}>
            {formatters.toTitleCase(env)}
          </Typography>
        )}
        <LanguageDropdown settings={settings} saveSettings={saveSettings} />
        <ModeToggler settings={settings} saveSettings={saveSettings} />
        <UserDropdown settings={settings} />
      </Box>
    </Box>
  );
}

import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

import { z } from 'zod';

import { jwtDecode } from 'jwt-decode';

import { toast } from 'sonner';

import configs from '@/configs/auth';
import tokenService from '@/services/TokenService';

export interface IToken {
  expiration: string;
  token: string;
}

export interface ILoginResponse {
  accessToken: IToken;
  refreshToken: IToken;
  permissions: Array<string>;
}

const decodedAccessTokenValidationSchema = z.object({
  jti: z.string().min(1),
  userId: z.string().min(1),
  firstName: z.string().min(1),
  lastName: z.string().min(1),
  email: z.string().email(),
  roles: z.array(z.string().min(1)),
  exp: z.number(),
  iss: z.string().min(1),
  aud: z.string().min(1),
  ipAddress: z.string().min(1),
});

export const permissionValidationSchema = z.array(
  z.object({
    resource: z.string().min(1),
    action: z.string().min(1),
    value: z.string().min(1),
  })
);

export const refreshTokenValidationSchema = z.object({
  expiration: z.string().min(1),
  token: z.string().min(1),
});

export const accessTokenValidationSchema = z.object({
  expiration: z.string().min(1),
  token: z.string().min(1),
  decodedToken: decodedAccessTokenValidationSchema,
});

export type Permissions = z.infer<typeof permissionValidationSchema>;
export type RefreshToken = z.infer<typeof refreshTokenValidationSchema>;
export type DecodedAccessToken = z.infer<typeof decodedAccessTokenValidationSchema>;
export type AccessToken = z.infer<typeof accessTokenValidationSchema>;

interface IAuthState {
  isAuthenticated: boolean;
  accessToken: AccessToken;
  refreshToken: RefreshToken;
  permissions: Permissions;
  isInitialized: boolean;
}

interface IAuthAction {
  addTokens: (
    payload: ILoginResponse & {
      showMessage?: boolean;
    }
  ) => void;
  removeTokens: () => void;
  getToken: (needsToBeExpired?: boolean) => Promise<AccessToken | null>;
}

const initialState: IAuthState = {
  accessToken: {
    expiration: '',
    token: '',
    decodedToken: {
      jti: '',
      userId: '',
      firstName: '',
      lastName: '',
      email: '',
      roles: [],
      exp: 0,
      iss: '',
      aud: '',
      ipAddress: '',
    },
  },
  refreshToken: { expiration: '', token: '' },
  permissions: [],
  isAuthenticated: false,
  isInitialized: false,
};

const useAuthStore = create<IAuthState & IAuthAction>()(
  devtools((set) => ({
    ...getInitialState(),
    getToken: async (needsToBeExpired = true) => {
      const accessToken = await tokenService.getAccessToken(needsToBeExpired);

      if (!accessToken) {
        if (
          window.location.pathname !== '/auth/login' &&
          window.location.pathname !== '/auth/forgot-password' &&
          window.location.pathname !== '/auth/change-password'
        ) {
          window.location.href = '/auth/login';
        }

        return null;
      }

      return accessToken;
    },
    addTokens: (payload: ILoginResponse & { showMessage?: boolean }) => {
      const decodedToken = transformAccessToken(payload.accessToken.token, payload.accessToken.expiration);

      set(
        (state) => {
          state.isAuthenticated = true;

          state.accessToken.token = payload.accessToken.token;
          state.accessToken.expiration = payload.accessToken.expiration;
          state.accessToken.decodedToken = decodedToken;

          state.refreshToken.token = payload.refreshToken.token;
          state.refreshToken.expiration = payload.refreshToken.expiration;

          state.permissions = parsePermission(payload.permissions);

          setTokens(state.accessToken, state.refreshToken, state.permissions);

          if (payload.showMessage && process.env.NEXT_PUBLIC_ENV !== 'cypress') {
            toast.success('You have successfully signed in.');
          }

          return state;
        },
        false,
        'addTokens'
      );
    },
    removeTokens: () => {
      set(
        () => {
          return { ...initialState };
        },
        false,
        'removeTokens'
      );

      clearTokens();

      if (process.env.NEXT_PUBLIC_ENV !== 'cypress') {
        toast.success('You have successfully signed out.');
      }
    },
  }))
);

function getInitialState(): IAuthState {
  if (typeof window === 'undefined') return initialState;

  const accessToken = localStorage.getItem(configs.accessTokenStorageKey);
  const refreshToken = localStorage.getItem(configs.refreshTokenStorageKey);
  const permissions = localStorage.getItem(configs.permissionsStorageKey);

  if (!accessToken) {
    clearTokens();
    return initialState;
  }

  if (!refreshToken) {
    clearTokens();
    return initialState;
  }

  if (!permissions) {
    clearTokens();
    return initialState;
  }

  const parsedAccessToken = JSON.parse(accessToken);
  const parsedRefreshToken = JSON.parse(refreshToken);
  const parsedPermissions = JSON.parse(permissions);

  const parsedAccessTokenResult = accessTokenValidationSchema.safeParse(parsedAccessToken);
  const parsedRefreshTokenResult = refreshTokenValidationSchema.safeParse(parsedRefreshToken);
  const parsedPermissionsResult = permissionValidationSchema.safeParse(parsedPermissions);

  if (!parsedAccessTokenResult.success || !parsedRefreshTokenResult.success || !parsedPermissionsResult.success) {
    clearTokens();
    return initialState;
  }

  return {
    isAuthenticated: true,
    isInitialized: true,
    accessToken: parsedAccessTokenResult.data,
    refreshToken: parsedRefreshTokenResult.data,
    permissions: parsedPermissionsResult.data,
  };
}

function transformAccessToken(token: string, expiration: string): DecodedAccessToken {
  if (token === '' || expiration === '') return initialState.accessToken.decodedToken;

  const decodedToken = jwtDecode<DecodedAccessToken>(token);

  // There is zero role
  if (!decodedToken.roles) {
    decodedToken.roles = [];
  }

  // There is only one role
  if (!Array.isArray(decodedToken.roles)) {
    decodedToken.roles = [decodedToken.roles];
  }

  return decodedToken;
}

function setTokens(accessToken: AccessToken, refreshToken: RefreshToken, permissions: Permissions): void {
  if (typeof window !== 'undefined') {
    localStorage.setItem(configs.accessTokenStorageKey, JSON.stringify(accessToken));
    localStorage.setItem(configs.refreshTokenStorageKey, JSON.stringify(refreshToken));
    localStorage.setItem(configs.permissionsStorageKey, JSON.stringify(permissions));
  }
}

function parsePermission(permissions: Array<string>): Permissions {
  return permissions.map((permission) => {
    return {
      value: permission,
      resource: permission.split('.')[1],
      action: permission.split('.')[2],
    };
  });
}

function clearTokens(): void {
  if (typeof window !== 'undefined') {
    localStorage.removeItem(configs.accessTokenStorageKey);
    localStorage.removeItem(configs.refreshTokenStorageKey);
    localStorage.removeItem(configs.permissionsStorageKey);
  }
}

export default useAuthStore;

import RubyService from '@/services/http/RubyService';

interface IAddAction {
  url: string;
  data: any;
}

class LegacyActionService extends RubyService {
  constructor() {
    super();

    this.addAction = this.addAction.bind(this);
  }

  url = '';

  async addAction({ url, data }: IAddAction) {
    const response = await this.post({ url, data });

    return response.data;
  }
}

export default new LegacyActionService();

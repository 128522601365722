import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  .use(Backend) // Enables the i18next backend
  .use(LanguageDetector) // Enable automatic language detection
  .use(initReactI18next) // Enables the hook initialization module
  .init({
    backend: {
      loadPath: '/locales/{{lng}}.json',
    },
    fallbackLng: 'en',
    debug: false,
    keySeparator: false,
    react: {
      useSuspense: false,
    },
    interpolation: {
      escapeValue: false,
      formatSeparator: ',',
    },
    detection: {
      convertDetectedLanguage: function (language) {
        if (language === 'en-US') {
          return 'en';
        }
        return language;
      },
    },
  });

export default i18n;

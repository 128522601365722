import { Toaster as ToasterContainer } from 'sonner';

import Box, { BoxProps } from '@mui/material/Box';
import { styled, useTheme } from '@mui/material/styles';

import { useSettings } from '@core/hooks/useSettings';

const Container = styled(Box)<BoxProps>(() => {
  return {
    '& .': {
      // zIndex: `${theme.zIndex.modal + 1} !important`,
    },
  };
});

export default function Toaster() {
  const theme = useTheme();
  const { settings } = useSettings();

  return (
    <Container>
      <ToasterContainer
        position={settings.toastPosition}
        theme={theme.palette.mode}
        visibleToasts={30}
        richColors
        closeButton
      />
    </Container>
  );
}

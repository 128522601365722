// ** This file has been modified - YC

// ** React
import { Fragment } from 'react';

// ** Type Imports
import { NavLink, NavGroup, LayoutProps, NavSectionTitle } from '@core/layouts/types';

// ** Custom Menu Components
import VerticalNavLink from './VerticalNavLink';
import VerticalNavGroup from './VerticalNavGroup';
import VerticalNavSectionTitle from './VerticalNavSectionTitle';

import Permissions from '@/components/utilities/Permissions';

interface Props {
  parent?: NavGroup;
  navHover?: boolean;
  navVisible?: boolean;
  groupActive: string[];
  isSubToSub?: NavGroup;
  currentActiveGroup: string[];
  navigationBorderWidth: number;
  settings: LayoutProps['settings'];
  saveSettings: LayoutProps['saveSettings'];
  setGroupActive: (value: string[]) => void;
  setCurrentActiveGroup: (item: string[]) => void;
  verticalNavItems?: LayoutProps['verticalLayoutProps']['navMenu']['navItems'];
}

const resolveNavItemComponent = (item: NavGroup | NavLink | NavSectionTitle) => {
  if ((item as NavSectionTitle).sectionTitle) return VerticalNavSectionTitle;
  if ((item as NavGroup).children) return VerticalNavGroup;

  return VerticalNavLink;
};

const VerticalNavItems = (props: Props) => {
  // ** Props
  const { verticalNavItems } = props;

  const RenderMenuItems = verticalNavItems?.map((item: NavGroup | NavLink | NavSectionTitle, index: number) => {
    const TagName: any = resolveNavItemComponent(item);

    return (
      <Permissions key={index} resource={item.resource}>
        <TagName {...props} item={item} />
      </Permissions>
    );
  });

  return <Fragment>{RenderMenuItems}</Fragment>;
};

export default VerticalNavItems;

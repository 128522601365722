import { Fragment, ReactNode } from 'react';

import usePermissions from '@/hooks/usePermissions';

export type PermissionResource = 'any' | string | string[];
export type PermissionAction = 'any' | 'Read' | 'Create' | 'Update' | 'Delete' | 'Filter' | 'Report';

interface PermissionsProps {
  resource: PermissionResource;
  action?: PermissionAction;
  children: ReactNode;
}

const Permissions = (props: PermissionsProps) => {
  const { resource, action, children } = props;

  const permissions = usePermissions();

  return permissions.has(resource, action) ? <Fragment>{children}</Fragment> : null;
};

export default Permissions;

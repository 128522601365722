import { HorizontalNavItemsType } from '@core/layouts/types';
import { BiEnvelope, BiHomeCircle, BiShield } from 'react-icons/bi';

export default function navigation(): HorizontalNavItemsType {
  return [
    {
      title: 'Home',
      path: '/home',
      icon: BiHomeCircle,
      resource: '',
    },
    {
      title: 'Second Page',
      path: '/second-page',
      icon: BiEnvelope,
      resource: '',
    },
    {
      path: '/acl',
      action: 'read',
      subject: 'acl-page',
      title: 'Access Control',
      icon: BiShield,
      resource: '',
    },
  ];
}

// ** This file has been modified - YC

// ** React Imports
import { useEffect, useCallback, useRef, useState, ChangeEvent, useMemo } from 'react';

// ** Next Imports
import Link from 'next/link';
import { useRouter } from 'next/router';

// ** MUI Imports
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import MuiDialog from '@mui/material/Dialog';
import ListItem from '@mui/material/ListItem';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import useMediaQuery from '@mui/material/useMediaQuery';
import { styled, useTheme } from '@mui/material/styles';
import ListItemButton from '@mui/material/ListItemButton';
import InputAdornment from '@mui/material/InputAdornment';
import MuiAutocomplete, { AutocompleteRenderInputParams } from '@mui/material/Autocomplete';
import { IconType } from 'react-icons';

// ** Types Imports
import { Settings } from 'src/@core/context/settingsContext';

// ** Icon Imports
import { MdOutlineSearch, MdOutlineLink, MdOutlineClose, MdOutlineSubdirectoryArrowLeft } from 'react-icons/md';
import { LuFileSearch, LuFileX2 } from 'react-icons/lu';
import { TbReportMoney } from 'react-icons/tb';

// ** Layouts Imports
import { NavGroup, NavLink, NavSectionTitle } from '@core/layouts/types';

// ** Hooks
import usePermissions from '@/hooks/usePermissions';

// ** Configs Imports
import getMenuItems from '@/navigation/vertical';

interface IProps {
  hidden: boolean;
  settings: Settings;
}

interface INoResultProps {
  value: string;
  setOpenDialog: (val: boolean) => void;
}

interface IAppBarSearchType {
  id: number;
  url: string;
  icon: IconType;
  title: string;
  category: string;
}

// ** Styled Autocomplete component
const Autocomplete = styled(MuiAutocomplete)(({ theme }) => ({
  '& fieldset': {
    border: 0,
  },
  '& + .MuiAutocomplete-popper': {
    '& .MuiAutocomplete-listbox': {
      paddingTop: 0,
      height: '100%',
      maxHeight: 'inherit',
      '& .MuiListSubheader-root': {
        top: 0,
        fontWeight: 400,
        lineHeight: '15px',
        fontSize: '0.75rem',
        letterSpacing: '1px',
        color: theme.palette.text.disabled,
      },
    },
    '& .MuiAutocomplete-paper': {
      border: 0,
      marginTop: 0,
      height: '100%',
      borderRadius: 0,
      boxShadow: 'none',
    },
    '& .MuiListItem-root.suggestion': {
      padding: 0,
      '& .MuiListItemSecondaryAction-root': {
        display: 'flex',
      },
      '&.Mui-focused.Mui-focusVisible, &:hover': {
        backgroundColor: theme.palette.action.hover,
      },
      '& .MuiListItemButton-root: hover': {
        backgroundColor: 'transparent',
      },
      '&:not(:hover)': {
        '& .MuiListItemSecondaryAction-root': {
          display: 'none',
        },
        '&.Mui-focused, &.Mui-focused.Mui-focusVisible:not(:hover)': {
          '& .MuiListItemSecondaryAction-root': {
            display: 'flex',
          },
        },
        [theme.breakpoints.down('sm')]: {
          '&.Mui-focused:not(.Mui-focusVisible) .MuiListItemSecondaryAction-root': {
            display: 'none',
          },
        },
      },
    },
    '& .MuiAutocomplete-noOptions': {
      display: 'grid',
      minHeight: '100%',
      alignItems: 'center',
      flexDirection: 'column',
      justifyContent: 'center',
      padding: theme.spacing(10),
    },
  },
}));

// ** Styled Dialog component
const Dialog = styled(MuiDialog)({
  '& .MuiBackdrop-root': {
    backdropFilter: 'blur(4px)',
  },
  '& .MuiDialog-paper': {
    overflow: 'hidden',
    '&:not(.MuiDialog-paperFullScreen)': {
      height: '100%',
      maxHeight: 420,
    },
  },
});

const NoResult = ({ value, setOpenDialog }: INoResultProps) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column', justifyContent: 'center' }}>
      <Box sx={{ mb: 2.5, mt: 0, color: 'text.primary' }}>
        <LuFileX2 size={40} />
      </Box>
      <Typography variant='h6' sx={{ mb: 10, wordWrap: 'break-word' }}>
        No results for{' '}
        <Typography variant='h6' component='span' sx={{ wordWrap: 'break-word' }}>
          {`"${value}"`}
        </Typography>
      </Typography>

      <Typography variant='body2' sx={{ mb: 2.5, color: 'text.disabled' }}>
        Try searching for
      </Typography>
      <List sx={{ py: 0 }}>
        <ListItem sx={{ py: 2 }} disablePadding onClick={() => setOpenDialog(false)}>
          <Box
            component={Link}
            href='/sales/quotes'
            sx={{
              display: 'flex',
              alignItems: 'center',
              textDecoration: 'none',
              '&:hover > *': { color: 'primary.main' },
            }}
          >
            <Box sx={{ mr: 1.5, display: 'flex', color: 'text.primary' }}>
              <TbReportMoney size={20} />
            </Box>
            <Typography variant='body2' sx={{ color: 'text.primary' }}>
              Quotes
            </Typography>
          </Box>
        </ListItem>
        <ListItem sx={{ py: 2 }} disablePadding onClick={() => setOpenDialog(false)}>
          <Box
            component={Link}
            href='/sales/projects'
            sx={{
              display: 'flex',
              alignItems: 'center',
              textDecoration: 'none',
              '&:hover > *': { color: 'primary.main' },
            }}
          >
            <Box sx={{ mr: 1.5, display: 'flex', color: 'text.primary' }}>
              <TbReportMoney size={20} />
            </Box>
            <Typography variant='body2' sx={{ color: 'text.primary' }}>
              Projects
            </Typography>
          </Box>
        </ListItem>
        <ListItem sx={{ py: 2 }} disablePadding onClick={() => setOpenDialog(false)}>
          <Box
            component={Link}
            href='/sales/customers'
            sx={{
              display: 'flex',
              alignItems: 'center',
              textDecoration: 'none',
              '&:hover > *': { color: 'primary.main' },
            }}
          >
            <Box sx={{ mr: 1.5, display: 'flex', color: 'text.primary' }}>
              <TbReportMoney size={20} />
            </Box>
            <Typography variant='body2' sx={{ color: 'text.primary' }}>
              Customers
            </Typography>
          </Box>
        </ListItem>
      </List>
    </Box>
  );
};

const NoSearch = () => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column', justifyContent: 'center' }}>
      <Box sx={{ mb: 2.5, color: 'text.primary' }}>
        <LuFileSearch size={40} />
      </Box>
      <Typography variant='h6' sx={{ mb: 11.5, wordWrap: 'break-word' }}>
        Search a page
      </Typography>
    </Box>
  );
};

const AutocompleteComponent = ({ hidden, settings }: IProps) => {
  // ** States
  const [isMounted, setIsMounted] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>('');
  const [openDialog, setOpenDialog] = useState<boolean>(false);

  // ** Hooks & Vars
  const theme = useTheme();
  const router = useRouter();
  const { layout } = settings;
  const wrapper = useRef<HTMLDivElement>(null);
  const fullScreenDialog = useMediaQuery(theme.breakpoints.down('sm'));

  // ** Shortcut keys to open search box (Ctrl + /)
  const handleKeydown = useCallback(
    (event: KeyboardEvent) => {
      if (!openDialog && event.ctrlKey && event.key === '/') {
        setOpenDialog(true);
      }
    },
    [openDialog]
  );

  const { has } = usePermissions();

  const getItemsFromMenu = useCallback(() => {
    const items = getMenuItems();
    const appBarSearchItems: IAppBarSearchType[] = [];
    let idCounter = 0;

    function processItem(item: NavLink | NavGroup | NavSectionTitle, inheritedIcon?: IconType, parentTitle?: string) {
      let category = 'Uncategorized';

      if (parentTitle && 'title' in item) {
        if (!('children' in item) || !item.children) {
          category = parentTitle;
        } else {
          category = `${parentTitle} - ${item.title}`;
        }
      } else {
        category = parentTitle || ('title' in item ? item.title : 'Uncategorized');
      }

      const icon = 'icon' in item && item.icon ? item.icon : inheritedIcon || MdOutlineLink;

      if (!('children' in item) || !item.children) {
        const appBarItem: IAppBarSearchType = {
          id: idCounter++,
          url: 'path' in item && item.path ? item.path : '',
          icon: icon,
          title: 'title' in item ? item.title : 'No Title',
          category: category,
        };

        if (appBarItem.url !== '' && has(item.resource)) {
          appBarSearchItems.push(appBarItem);
        }
      }

      if ('children' in item && item.children) {
        item.children.forEach((child) => processItem(child, icon, category));
      }
    }

    items.forEach((item) => processItem(item));

    return appBarSearchItems.sort((a, b) => a.category.localeCompare(b.category));
  }, []);

  const menuItems = useMemo(() => getItemsFromMenu(), []);

  useEffect(() => {
    document.addEventListener('keydown', handleKeydown);

    return () => {
      document.removeEventListener('keydown', handleKeydown);
    };
  }, [handleKeydown]);

  useEffect(() => {
    if (!openDialog) {
      setSearchValue('');
    }
  }, [openDialog]);

  useEffect(() => {
    setIsMounted(true);

    return () => setIsMounted(false);
  }, []);

  // Handle click event on a list item in search result
  const handleOptionClick = async (obj: IAppBarSearchType) => {
    setSearchValue('');
    setOpenDialog(false);

    if (obj.url) {
      await router.push(obj.url);
    }
  };

  const renderIcon = (option: IAppBarSearchType | unknown) => {
    if (typeof option === 'object' && option !== null && 'icon' in option) {
      const iconOption = option as { icon: React.ElementType | null };

      if (iconOption.icon) {
        const IconComponent = iconOption.icon;
        return <IconComponent size={20} />;
      }
    }
    return null;
  };

  if (!isMounted) {
    return null;
  } else {
    return (
      <Box
        ref={wrapper}
        onClick={() => !openDialog && setOpenDialog(true)}
        sx={{ display: 'flex', cursor: 'pointer', alignItems: 'center' }}
      >
        <IconButton color='inherit' sx={!hidden && layout === 'vertical' ? { mr: 1, ml: -2.75 } : {}}>
          <MdOutlineSearch />
        </IconButton>
        {!hidden && layout === 'vertical' ? (
          <Typography sx={{ userSelect: 'none', color: 'text.disabled' }}>Search (Ctrl+/)</Typography>
        ) : null}
        {openDialog && (
          <Dialog fullWidth open={openDialog} fullScreen={fullScreenDialog} onClose={() => setOpenDialog(false)}>
            <Box sx={{ top: 0, width: '100%', position: 'sticky' }}>
              <Autocomplete
                autoHighlight
                disablePortal
                options={menuItems}
                isOptionEqualToValue={() => true}
                onInputChange={(_, value: string) => setSearchValue(value)}
                onChange={(_, obj) => handleOptionClick(obj as IAppBarSearchType)}
                noOptionsText={<NoResult value={searchValue} setOpenDialog={setOpenDialog} />}
                getOptionLabel={(option: IAppBarSearchType | unknown) => (option as IAppBarSearchType).title || ''}
                groupBy={(option: IAppBarSearchType | unknown) =>
                  searchValue.length ? (option as IAppBarSearchType).category : ''
                }
                sx={{
                  '& + .MuiAutocomplete-popper': {
                    ...(searchValue.length
                      ? {
                          overflow: 'auto',
                          maxHeight: 'calc(100vh - 69px)',
                          borderTop: `1px solid ${theme.palette.divider}`,
                          height: fullScreenDialog ? 'calc(100vh - 69px)' : 350,
                          '& .MuiListSubheader-root': { p: theme.spacing(3.75, 6, 0.75) },
                        }
                      : {
                          '& .MuiAutocomplete-listbox': { pb: 0 },
                        }),
                  },
                }}
                renderInput={(params: AutocompleteRenderInputParams) => {
                  return (
                    <TextField
                      {...params}
                      placeholder='Search...'
                      value={searchValue}
                      onChange={(event: ChangeEvent<HTMLInputElement>) => setSearchValue(event.target.value)}
                      inputRef={(input) => {
                        if (input) {
                          if (openDialog) {
                            input.focus();
                          } else {
                            input.blur();
                          }
                        }
                      }}
                      InputProps={{
                        ...params.InputProps,
                        sx: {
                          p: `${theme.spacing(3.75, 5)} !important`,
                          '&.Mui-focused': { boxShadow: 'none !important' },
                        },
                        startAdornment: (
                          <InputAdornment position='start' sx={{ color: 'text.primary' }}>
                            <MdOutlineSearch size={25} />
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment
                            position='end'
                            onClick={() => setOpenDialog(false)}
                            sx={{ display: 'flex', cursor: 'pointer', alignItems: 'center' }}
                          >
                            {!hidden ? <Typography sx={{ mr: 1.5, color: 'text.disabled' }}>[esc]</Typography> : null}
                            <IconButton size='small' sx={{ p: 1 }}>
                              <MdOutlineClose size={25} />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  );
                }}
                renderOption={(props, option: IAppBarSearchType | unknown) => {
                  return searchValue.length ? (
                    <ListItem
                      {...props}
                      key={(option as IAppBarSearchType).url}
                      className={`suggestion ${props.className}`}
                      onClick={() => handleOptionClick(option as IAppBarSearchType)}
                      secondaryAction={<MdOutlineSubdirectoryArrowLeft size={15} />}
                      sx={{
                        '& .MuiListItemSecondaryAction-root': {
                          '& svg': {
                            cursor: 'pointer',
                            color: 'text.disabled',
                          },
                        },
                      }}
                    >
                      <ListItemButton
                        sx={{
                          py: 2.5,
                          px: `${theme.spacing(6)} !important`,
                          '& svg': { mr: 2.5, color: 'text.primary' },
                        }}
                      >
                        {renderIcon(option)}
                        <Typography variant='body2' sx={{ color: 'text.primary' }}>
                          {(option as IAppBarSearchType).title}
                        </Typography>
                      </ListItemButton>
                    </ListItem>
                  ) : null;
                }}
              />
            </Box>
            {searchValue.length === 0 ? (
              <Box
                sx={{
                  p: 10,
                  display: 'grid',
                  overflow: 'auto',
                  borderTop: `1px solid ${theme.palette.divider}`,
                  height: fullScreenDialog ? 'calc(100vh - 69px)' : '100%',
                }}
              >
                <NoSearch />
              </Box>
            ) : null}
          </Dialog>
        )}
      </Box>
    );
  }
};

export default AutocompleteComponent;

import { Fragment, ElementType, ReactNode, useState } from 'react';

import { FieldValues } from 'react-hook-form/dist/types/fields';

import { MdOutlineEdit } from 'react-icons/md';

import Permissions, { PermissionAction, PermissionResource } from '@/components/utilities/Permissions';

import { IForm } from '@/components/forms/Form';
import TooltipAction from '@/components/actions/shared/TooltipAction';

import EditActionDialog, { IEditActionDialogProps } from './EditActionDialog';

interface IEditActionProps<TValue extends FieldValues, TForm>
  extends Omit<IEditActionDialogProps<TValue, TForm>, 'setOpen'> {
  resource: PermissionResource;
  action?: PermissionAction;
  open?: boolean;
  children?: ReactNode;

  // ActionTooltip
  tooltip?: string;
  icon?: ElementType;
  iconSize?: number;
  displayAsMenu?: boolean;
  onClick?: () => void;
}

export default function EditAction<TValue extends FieldValues, TForm extends IForm<unknown>>(
  props: IEditActionProps<TValue, TForm>
) {
  const {
    resource,
    action = 'Update',
    open: openProp = false,
    onClick,
    children,
    tooltip = 'Edit',
    icon: Icon = MdOutlineEdit,
    iconSize,
    displayAsMenu,
    ...rest
  } = props;

  const [open, setOpen] = useState(openProp);

  function handleOnClick() {
    setOpen(true);
    onClick?.();
  }

  function renderIcon() {
    return (
      <Permissions resource={resource} action={action}>
        {children ? (
          <div onClick={handleOnClick}>{children}</div>
        ) : (
          <TooltipAction
            title={tooltip}
            onClick={handleOnClick}
            icon={Icon}
            iconSize={iconSize}
            displayAsMenu={displayAsMenu}
          />
        )}
      </Permissions>
    );
  }

  if (!open) return renderIcon();

  return (
    <Fragment>
      {renderIcon()}
      <EditActionDialog setOpen={setOpen} {...rest} />
    </Fragment>
  );
}

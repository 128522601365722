import { Fragment, KeyboardEvent, ReactNode } from 'react';

import { useFormState } from 'react-hook-form';
import { FieldValues } from 'react-hook-form/dist/types/fields';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import DialogContentText from '@mui/material/DialogContentText';

import Loading from '@/components/utilities/Loading';
import FormContainer, { FormContainerProps } from '@/components/forms/FormContainer';

import { IContentDialogProps } from './ContentDialog';
import DialogTitle from './shared/DialogTitle';

interface IFormState {
  isValid: boolean;
  isDirty: boolean;
  isSubmitting: boolean;
  actions: ReactNode;
}

interface IDefaultActionProps {
  submitText?: string;
  closeText?: string;
  onClose?: () => void;
  isValid?: boolean;
  shouldBeDirty?: boolean;
  actions?: (({ isValid, isDirty, isSubmitting, actions }: IFormState) => ReactNode) | null;
}

export interface IFormDialogProps<T extends FieldValues>
  extends IDefaultActionProps,
    Omit<IContentDialogProps, 'actions'>,
    FormContainerProps<T> {}

function DefaultActions(props: IDefaultActionProps) {
  const { onClose, submitText, closeText, shouldBeDirty, actions, isValid: isValidProp = true } = props;

  const { isValid, isDirty, isSubmitting } = useFormState();

  const getDirtyValidation = () => {
    return shouldBeDirty ? !isDirty : false;
  };

  const defaultActions = () => {
    return (
      <Fragment>
        <Button
          type='submit'
          color='primary'
          disabled={getDirtyValidation() || !isValid || !isValidProp || isSubmitting}
        >
          {isSubmitting ? <CircularProgress size='1rem' /> : submitText}
        </Button>
        <Button disabled={isSubmitting} onClick={onClose} color='warning'>
          {closeText}
        </Button>
      </Fragment>
    );
  };

  if (actions) {
    return <Fragment>{actions({ isValid, isDirty, isSubmitting, actions: defaultActions() })}</Fragment>;
  }

  return defaultActions();
}

export default function FormDialog<T extends FieldValues>(props: IFormDialogProps<T>) {
  const {
    title,
    message,
    open,
    loading,
    maxWidth,
    actions,
    validationSchema,
    validationMode,
    formContext,
    devTools = true,
    fullScreen,
    defaultValues,
    submitText = 'Save',
    closeText = 'Cancel',
    onSubmit,
    onClose,
    children,
    shouldUnregister,
    shouldBeDirty = true,
  } = props;

  const disableActions = formContext !== undefined ? formContext.formState.isSubmitting : false;

  function handleEscapeKey(event: KeyboardEvent<HTMLDivElement>) {
    event.stopPropagation();

    if (event && event.key === 'Escape') {
      onClose?.();
    }
  }

  return (
    <Dialog open={open} maxWidth={maxWidth} fullWidth={true} fullScreen={fullScreen} onKeyUp={handleEscapeKey}>
      {loading && <Loading />}
      <DialogTitle title={title} onClose={onClose} disableActions={disableActions || loading} />
      <FormContainer
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        validationMode={validationMode}
        formContext={formContext}
        devTools={devTools}
        defaultValues={defaultValues}
        shouldUnregister={shouldUnregister}
      >
        <DialogContent dividers={true}>
          {message && <DialogContentText>{message}</DialogContentText>}
          {children}
        </DialogContent>
        {actions !== null && (
          <DialogActions>
            <DefaultActions
              submitText={submitText}
              closeText={closeText}
              onClose={onClose}
              actions={actions}
              shouldBeDirty={shouldBeDirty}
            />
          </DialogActions>
        )}
      </FormContainer>
    </Dialog>
  );
}

import { ElementType, createElement, Dispatch, SetStateAction } from 'react';

import { useForm } from 'react-hook-form';
import { FieldValues } from 'react-hook-form/dist/types/fields';
import { zodResolver } from '@hookform/resolvers/zod';

import { ZodSchema } from 'zod';

import FormDialog, { IFormDialogProps } from '@/components/dialogs/FormDialog';
import { IForm, IFormExtras } from '@/components/forms/Form';

export interface IVoidActionDialogProps<TValue extends FieldValues, TForm>
  extends Omit<IFormDialogProps<TValue>, 'loading' | 'open' | 'onSubmit' | 'onClose' | 'children' | 'formContext'> {
  form: ElementType<TForm>;
  validationSchema?: ZodSchema<TValue>;
  onSuccess: (values: any) => void;
  onCancel?: () => void;
  propData?: TForm extends IForm<unknown, infer A, unknown> ? A & IFormExtras<TValue> : never;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

export default function VoidActionDialog<TValue extends FieldValues, TForm extends IForm<unknown>>(
  props: IVoidActionDialogProps<TValue, TForm>
) {
  const {
    form,
    validationSchema,
    validationMode = 'onChange',
    maxWidth = 'sm',
    onSuccess,
    onCancel,
    propData,
    setOpen,
    ...rest
  } = props;

  const formMethods = useForm<any>({
    defaultValues: propData?.values,
    mode: validationMode,
    resolver: validationSchema ? zodResolver(validationSchema) : undefined,
  });

  const { formState } = formMethods;

  async function handleOnSubmit(data: any) {
    setOpen(false);
    onSuccess(data);
  }

  function handleOnCancel() {
    setOpen(false);
    onCancel?.();
  }

  function renderForm() {
    const newContext: TForm = {
      context: { action: 'void' },
      propData: propData ?? {},
    } as TForm;

    return createElement(form, { ...newContext }, null);
  }

  return (
    <FormDialog
      {...rest}
      loading={formState.isSubmitting}
      open={true}
      onSubmit={handleOnSubmit}
      onClose={handleOnCancel}
      maxWidth={maxWidth}
      validationSchema={validationSchema}
      formContext={formMethods}
    >
      {renderForm()}
    </FormDialog>
  );
}

import axios from 'axios';

import { jwtDecode } from 'jwt-decode';

import useAuthStore, {
  AccessToken,
  DecodedAccessToken,
  ILoginResponse,
  IToken,
  RefreshToken,
} from '@/stores/useAuthStore';

import configs from '@/configs/auth';

class TokenService {
  private isRefreshRequesting = false;

  async getAccessToken(needsToBeExpired = true): Promise<AccessToken | null> {
    if (typeof window === 'undefined') return null;

    const item = localStorage.getItem(configs.accessTokenStorageKey);

    if (item === null) return null;

    const parsedToken = JSON.parse(item) as AccessToken;

    const isExpired = needsToBeExpired ? this.isTokenExpired(parsedToken.expiration) : true;

    let accessToken: IToken = {
      token: parsedToken.token,
      expiration: parsedToken.expiration,
    };

    if (isExpired && !this.isRefreshRequesting) {
      this.isRefreshRequesting = true;
      accessToken = await this.getNewToken(parsedToken);
      this.isRefreshRequesting = false;
    } else if (this.isRefreshRequesting) {
      accessToken = await this.waitForNewToken(parsedToken.token);
    }

    const decodedToken = jwtDecode<DecodedAccessToken>(accessToken.token);

    // There is zero role
    if (decodedToken.roles === undefined) decodedToken.roles = [];

    // There is only one role
    if (!Array.isArray(decodedToken.roles)) decodedToken.roles = [decodedToken.roles];

    return {
      decodedToken: decodedToken,
      token: accessToken.token,
      expiration: accessToken.expiration,
    };
  }

  private async getNewToken(accessToken: AccessToken): Promise<IToken> {
    const baseApiUrl = process.env.NEXT_PUBLIC_API_URL;
    const refreshTokenUrl = '/v1/auth/RefreshToken';

    return new Promise<IToken>(async (resolve, reject) => {
      const refreshToken = this.getRefreshToken();

      axios
        .post<ILoginResponse>(baseApiUrl + refreshTokenUrl, {
          accessToken: accessToken?.token,
          refreshToken: refreshToken?.token,
        })
        .then((response) => {
          useAuthStore.getState().addTokens(response.data);
          resolve(response.data.accessToken);
        })
        .catch((error) => {
          reject(error);
        })
        .finally(() => {
          this.isRefreshRequesting = false;
        });
    });
  }

  private getRefreshToken(): RefreshToken | null {
    if (typeof window === 'undefined') return null;

    const item = localStorage.getItem(configs.refreshTokenStorageKey);

    return item !== null ? JSON.parse(item) : null;
  }

  private waitForNewToken(token: string, maxTry = 5, currentTry = 0): Promise<IToken> {
    return new Promise<IToken>((resolve, reject) => {
      const handler = setInterval(() => {
        const accessToken = this.getAccessTokenInternal();

        if (accessToken !== null && accessToken.token !== token) {
          resolve(accessToken);
        }

        if (++currentTry >= maxTry) {
          clearInterval(handler);
          reject(null);
        }
      }, 1000);
    });
  }

  private getAccessTokenInternal(): IToken | null {
    if (typeof window === 'undefined') return null;

    const item = localStorage.getItem(configs.accessTokenStorageKey);

    if (item === null) return null;

    const parsedToken = JSON.parse(item) as AccessToken;

    return {
      token: parsedToken.token,
      expiration: parsedToken.expiration,
    };
  }

  private isTokenExpired(expiration: string): boolean {
    const expDate = new Date(expiration);
    const currentDate = new Date();

    return currentDate > expDate;
  }
}

export default new TokenService();

import { ElementType, useState } from 'react';

import actionService from '@/services/http/ActionService';

import { isMobile } from 'react-device-detect';

import TooltipAction from '@/components/actions/shared/TooltipAction';
import ConfirmDialog from '@/components/dialogs/ConfirmDialog';
import ContentDialog from '@/components/dialogs/ContentDialog';

import Permissions, { PermissionAction, PermissionResource } from '@/components/utilities/Permissions';

interface IReportActionProps {
  resource: PermissionResource;
  action?: PermissionAction;

  url: string;
  confirmDialogText?: string;
  confirmDialogTitle?: string;
  confirmDialog?: boolean;

  // ActionTooltip
  tooltip: string;
  icon: ElementType;
  iconSize?: number;
  displayAsMenu?: boolean;
  onClick?: () => void;
}

const ReportAction = (props: IReportActionProps) => {
  const {
    url,
    tooltip,
    confirmDialog = true,
    confirmDialogTitle,
    confirmDialogText,
    icon: Icon,
    iconSize,
    displayAsMenu,
    resource,
    action,
    onClick,
  } = props;

  const [data, setData] = useState<string | undefined>(undefined);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [reportOpen, setReportOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  async function handleConfirmation() {
    onClick?.();
    setLoading(true);

    try {
      const response = await actionService.get<string>(url, { responseType: 'arraybuffer' });

      setData(response.data);
      setReportOpen(true);
    } catch (error) {
      console.error('Error fetching report:', error);
    } finally {
      setLoading(false);
      setConfirmOpen(false);
    }
  }

  function handleOnClose() {
    setReportOpen(false);
    setData(undefined);
  }

  function handleTooltipClick() {
    setConfirmOpen(true);
    onClick?.();
  }

  function RenderIcon() {
    return confirmDialog ? (
      <TooltipAction
        title={tooltip}
        icon={Icon}
        iconSize={iconSize}
        onClick={handleTooltipClick}
        displayAsMenu={displayAsMenu}
      />
    ) : (
      <TooltipAction
        title={tooltip}
        icon={Icon}
        iconSize={iconSize}
        onClick={handleConfirmation}
        displayAsMenu={displayAsMenu}
      />
    );
  }

  if (data) {
    const file = new Blob([data], { type: 'application/pdf' });
    const url = URL.createObjectURL(file);

    if (isMobile) {
      window.open(url, '_blank');

      handleOnClose();

      return;
    }

    return (
      <ContentDialog title='Report' open={reportOpen} onClose={handleOnClose} fullScreen>
        <div style={{ height: '100%' }}>
          <object data={url} width='100%' height='100%' />
        </div>
      </ContentDialog>
    );
  }

  if (!confirmOpen) {
    return (
      <Permissions resource={resource} action={action}>
        <RenderIcon />
      </Permissions>
    );
  }

  return (
    <Permissions resource={resource} action={action}>
      <RenderIcon />
      <ConfirmDialog
        loading={loading}
        open={confirmOpen}
        title={confirmDialogTitle}
        message={confirmDialogText}
        onConfirm={handleConfirmation}
        onCancel={() => setConfirmOpen(false)}
      />
    </Permissions>
  );
};

export default ReportAction;

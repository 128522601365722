import Stack from '@mui/material/Stack';

import { z } from 'zod';

import TextInput from '@/components/forms/inputs/TextInput';

export const validationSchema = z
  .object({
    currentPassword: z.string().min(1, 'Current Password is required'),
    password: z
      .string()
      .regex(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@!%*?&])[A-Za-z\d$@!%*?&]{6,}/,
        'Password must have at least one of the following: minimum 6, lower case, upper case, number and special character'
      ),
    confirmPassword: z.string().min(1, 'Confirm Password is required'),
  })
  .refine((values) => values.password === values.confirmPassword, {
    path: ['confirmPassword'],
    message: 'Password and Confirm Password does not match',
  });

export type FormValues = z.infer<typeof validationSchema>;

const ProfilePasswordForm = () => {
  return (
    <Stack spacing={1}>
      <TextInput name='currentPassword' type='password' onKeyDown={(e) => e.stopPropagation()} required />
      <TextInput name='password' type='password' onKeyDown={(e) => e.stopPropagation()} required />
      <TextInput name='confirmPassword' type='password' onKeyDown={(e) => e.stopPropagation()} required />
    </Stack>
  );
};

export default ProfilePasswordForm;

// ** This file has been modified - YC

// ** MUI Imports
import { ThemeOptions, useTheme } from '@mui/material';

import '@mui/x-data-grid-pro/themeAugmentation';

// ** To use core palette, uncomment the below import
// import { PaletteMode } from '@mui/material'

// ** To use mode (light/dark/semi-dark), skin(default/bordered), direction(ltr/rtl), etc. for conditional styles, uncomment below line
import { useSettings } from '@core/hooks/useSettings';

export default function UserThemeOptions(): ThemeOptions {
  // ** To use mode (light/dark/semi-dark), skin(default/bordered), direction(ltr/rtl), etc. for conditional styles, uncomment below line
  const { settings } = useSettings();

  const theme = useTheme();

  // ** To use mode (light/dark/semi-dark), skin(default/bordered), direction(ltr/rtl), etc. for conditional styles, uncomment below line
  const { mode } = settings;

  // ** To use core palette, uncomment the below line
  // const palette = corePalette(mode as PaletteMode, settings)

  // https://colors.eva.design/
  // light : 400
  // main : 500
  // dark : 600
  // contrastText: '#FFF'

  // ** Vars
  const lightColor = '50, 71, 92';
  const darkColor = '219, 219, 235';
  const mainColor = mode === 'light' ? lightColor : darkColor;

  return {
    palette: {
      primary: {
        light: '#39ADE6',
        main: '#0083D6',
        dark: '#0065B8',
        contrastText: '#FFF',
      },
      action: {
        active: `rgba(${mainColor}, 0.8)`,
        hover: `rgba(${mainColor}, 0.04)`,
        selected: `rgba(${mainColor}, 0.08)`,
        disabled: `rgba(${mainColor}, 0.26)`,
        disabledBackground: `rgba(${mainColor}, 0.12)`,
        focus: `rgba(${mainColor}, 0.12)`,
      },
    },
    components: {
      MuiDialogActions: {
        styleOverrides: {
          root: {
            padding: theme.spacing(0, 0, 0, 0),
            margin: theme.spacing(1, 1, 0.8, 0),
          },
        },
      },
      MuiPaper: {
        defaultProps: {
          elevation: 6,
        },
      },
      MuiDataGrid: {
        styleOverrides: {
          columnHeaders: {
            bottomTop: 0,
          },
          columnHeader: {
            '&:not(.MuiDataGrid-columnHeaderCheckbox)': {
              padding: theme.spacing(0, 0, 0, 1.5),
              '&:first-of-type': {
                paddingLeft: theme.spacing(1.5),
              },
            },
          },
          columnHeaderTitleContainer: {
            justifyContent: 'left !important',
          },
          cell: {
            justifyContent: 'left',
            '&:not(.MuiDataGrid-cellCheckbox)': {
              padding: theme.spacing(1, 1, 1, 1.5),
              '&:first-of-type': {
                paddingLeft: theme.spacing(1.5),
              },
            },
          },
        },
      },
      MuiSelect: {
        styleOverrides: {
          select: {
            '&:focus': {
              backgroundColor: 'transparent',
            },
          },
        },
      },
      MuiStack: {
        defaultProps: {
          spacing: 2.5,
        },
      },
      MuiIconButton: {
        styleOverrides: {
          root: {
            padding: 6,
          },
        },
      },
      MuiAutocomplete: {
        styleOverrides: {
          clearIndicator: {
            marginRight: 3,
          },
        },
      },
      MuiList: {
        styleOverrides: {
          root: {
            scrollbarWidth: 'thin',
          },
        },
      },
    },
    zIndex: {
      appBar: 1100,
      drawer: 1200,
    },
    mixins: {
      toolbar: {
        minHeight: 55,
      },
    },
  };
}

import axios, { AxiosHeaders, RawAxiosRequestConfig } from 'axios';

import { z } from 'zod';

import { AxiosResponse } from 'axios';

import HttpService from '@/services/HttpService';
import { IRubyGenericResponse } from '@/services/http/legacy/types';

import configs from '@/configs/auth';

export interface IPostAction {
  url: string;
  data: any;
}

export interface IPatchAction {
  url: string;
  data?: any;
}

export const rubyAuthSchema = z.object({
  uid: z.string().min(1),
  client: z.string().min(1),
  accessToken: z.string().min(1),
});

export type RubyAuth = z.infer<typeof rubyAuthSchema>;

// Axios
const axiosInstance = axios.create({
  baseURL: process.env.NEXT_PUBLIC_API_RUBY_URL,
  headers: {
    'token-type': 'Bearer',
  },
});

axiosInstance.interceptors.request.use(
  async (config) => {
    config.paramsSerializer = {
      indexes: true, // 'a[0]=b&a[1]=c'
    };

    const rubyService = new RubyService();

    let rubyAuth: RubyAuth;

    const rubyAuthString = localStorage.getItem(configs.rubyAuth);

    if (rubyAuthString !== null) {
      const result = rubyAuthSchema.safeParse(JSON.parse(rubyAuthString));

      if (result.success) {
        rubyAuth = result.data;
      } else {
        rubyAuth = await rubyService.getAndStoreRubyAuth();
      }
    } else {
      rubyAuth = await rubyService.getAndStoreRubyAuth();
    }

    (config.headers as AxiosHeaders).set('access-token', rubyAuth.accessToken);
    (config.headers as AxiosHeaders).set('client', rubyAuth.client);
    (config.headers as AxiosHeaders).set('uid', rubyAuth.uid);

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const rubyService = new RubyService();

    const config = error?.config;

    if (error?.response?.status === 401 && !config?.sent) {
      config.sent = true;

      const rubyAuth = await rubyService.getAndStoreRubyAuth();

      (config.headers as AxiosHeaders).set('access-token', rubyAuth.accessToken);
      (config.headers as AxiosHeaders).set('client', rubyAuth.client);
      (config.headers as AxiosHeaders).set('uid', rubyAuth.uid);

      return axiosInstance(config);
    }

    return Promise.reject(error);
  }
);

// Service
class RubyService extends HttpService {
  constructor() {
    super();
  }

  url = '';
  authUrl = '/v1/Auth/RubyLogin';

  getAndStoreRubyAuth = async () => {
    const result = await this.getBase<RubyAuth>(this.authUrl);

    localStorage.setItem(configs.rubyAuth, JSON.stringify(result.data));

    return result.data;
  };

  protected async get<T>(url: string, config?: RawAxiosRequestConfig): Promise<AxiosResponse<T>> {
    return await axiosInstance.get<T>(url, config);
  }

  protected async post<T = IRubyGenericResponse>({ url, data }: IPostAction): Promise<T> {
    const result = await axiosInstance.post<T>(url, data);

    return result.data;
  }

  protected async patch({ url, data }: IPatchAction): Promise<IRubyGenericResponse> {
    const result = await axiosInstance.patch<IRubyGenericResponse>(url, data);

    return result.data;
  }
}

export default RubyService;

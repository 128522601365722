import { ReactElement, SyntheticEvent, useState, useEffect } from 'react';

import { useRouter } from 'next/router';

import { toast } from 'sonner';

import Button from '@mui/material/Button';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import DialogActions from '@mui/material/DialogActions';

import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';

import { useMutation, useQuery } from '@tanstack/react-query';
import userService, { IUserProfile } from '@/services/http/UserService';

import useAuthStore from '@/stores/useAuthStore';

import { DialogAction } from '@/components/actions';
import FormContainer from '@/components/forms/FormContainer';

import Loading from '@/components/utilities/Loading';

import ProfilePasswordForm, {
  validationSchema as profilePasswordValidationSchema,
  FormValues as profilePasswordFormValues,
} from './ProfilePasswordForm';
import ProfileInfoForm, {
  validationSchema as profileInfoValidationSchema,
  FormValues as ProfileInfoFormValues,
} from './ProfileInfoForm';

interface IProfileProps {
  children: ReactElement;
  onClose?: () => void;
}

interface IStep {
  number: number;
  formId: string;
}

const formIds = ['profile-form', 'password-form'];

function TabPanel(props: { children: ReactElement; value: number; index: number }) {
  const { children, value, index } = props;

  return <div hidden={value !== index}>{value === index && <Box>{children}</Box>}</div>;
}

export default function Profile(props: IProfileProps) {
  const { children, onClose } = props;

  const [open, setOpen] = useState(false);
  const [step, setStep] = useState<IStep>({ number: 0, formId: formIds[0] });

  const router = useRouter();

  const addTokens = useAuthStore((state) => state.addTokens);

  const profileInfoContext = useForm<ProfileInfoFormValues>({
    mode: 'onChange',
    resolver: zodResolver(profileInfoValidationSchema),
  });

  const {
    reset: profileReset,
    formState: { isValid: profileInfoIsValid, isDirty: profileInfoIsDirty, isSubmitting: profileInfoIsSubmitting },
  } = profileInfoContext;

  const profilePasswordContext = useForm<profilePasswordFormValues>({
    mode: 'onChange',
    resolver: zodResolver(profilePasswordValidationSchema),
  });

  const {
    reset: passwordReset,
    formState: {
      isValid: profilePasswordIsValid,
      isDirty: profilePasswordIsDirty,
      isSubmitting: profilePasswordIsSubmitting,
    },
  } = profilePasswordContext;

  const profileInfoMutation = useMutation({ mutationFn: userService.updateProfile });
  const profilePasswordMutation = useMutation({ mutationFn: userService.updatePassword });

  const { data, isFetching } = useQuery<IUserProfile>({
    queryFn: () => userService.getProfile(),
    queryKey: ['userService.getProfile'],
    enabled: open,
  });

  useEffect(() => {
    profileReset(data);
  }, [profileReset, data]);

  const handleOnChange = (_event: SyntheticEvent, newStep: number) => {
    setStep({ number: newStep, formId: formIds[newStep] });
  };

  const getIsValid = (): boolean => {
    if (step.number === 0) return profileInfoIsValid && profileInfoIsDirty;
    if (step.number === 1) return profilePasswordIsValid && profilePasswordIsDirty;

    return false;
  };

  const getIsSubmitting = (): boolean => {
    if (step.number === 0) return profileInfoIsSubmitting;
    if (step.number === 1) return profilePasswordIsSubmitting;

    return false;
  };

  const handleOnClose = () => {
    profileReset();
    passwordReset();

    setOpen(false);

    onClose?.();
  };

  const handleProfileInfoOnSubmit = async (data: ProfileInfoFormValues) => {
    const result = await profileInfoMutation.mutateAsync(data);

    handleOnClose();

    addTokens({ ...result, showMessage: false });
    toast.success('The profile information was successfully updated.');
  };

  const handleProfilePasswordOnSubmit = async (data: profilePasswordFormValues) => {
    await profilePasswordMutation.mutateAsync(data);

    handleOnClose();

    await router.push('/auth/login');
    toast.success('The password was successfully edited. Please sign in.');
  };

  const renderActions = () => {
    return (
      <DialogActions>
        <Button type='submit' form={step.formId} disabled={!getIsValid()} color='primary'>
          Save
        </Button>
        <Button disabled={getIsSubmitting()} onClick={handleOnClose} color='warning'>
          Close
        </Button>
      </DialogActions>
    );
  };

  return (
    <DialogAction
      resource='any'
      title='Edit'
      open={open}
      onClick={() => setOpen(true)}
      onClose={handleOnClose}
      clickContent={children}
      actions={() => renderActions()}
      loading={isFetching}
    >
      <Loading variant='form' isLoading={isFetching}>
        <Box>
          <Tabs value={step.number} onChange={handleOnChange} centered>
            <Tab label='Profile' />
            <Tab label='Password' />
          </Tabs>
          <TabPanel value={step.number} index={0}>
            <FormContainer formContext={profileInfoContext} onSubmit={handleProfileInfoOnSubmit} formId={formIds[0]}>
              <ProfileInfoForm />
            </FormContainer>
          </TabPanel>
          <TabPanel value={step.number} index={1}>
            <FormContainer
              formContext={profilePasswordContext}
              onSubmit={handleProfilePasswordOnSubmit}
              formId={formIds[1]}
            >
              <ProfilePasswordForm />
            </FormContainer>
          </TabPanel>
        </Box>
      </Loading>
    </DialogAction>
  );
}

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import MuiDialogTitle from '@mui/material/DialogTitle';
import { styled, Theme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { MdOutlineClose } from 'react-icons/md';
import Typography from '@mui/material/Typography';

interface IDialogTitleProps {
  title: string;
  disableActions?: boolean;
  onClose?: () => void;
}

const DialogTitleStyled = styled(MuiDialogTitle)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '8px 8px 8px 20px !important',
}));

export default function DialogTitle(props: IDialogTitleProps) {
  const { title, disableActions, onClose } = props;

  const hidden = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));

  function handleClose() {
    onClose?.();
  }

  return (
    <DialogTitleStyled>
      {title}
      {onClose && (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {!hidden ? <Typography sx={{ mr: 1.5, color: 'text.disabled' }}>[esc]</Typography> : null}
          <IconButton onClick={handleClose} disabled={disableActions}>
            <MdOutlineClose />
          </IconButton>
        </Box>
      )}
    </DialogTitleStyled>
  );
}

import { Fragment, ReactNode } from 'react';

import Button from '@mui/material/Button';
import DialogContentText from '@mui/material/DialogContentText';
import CircularProgress from '@mui/material/CircularProgress';

import ContentDialog, { IContentDialogProps } from './ContentDialog';

interface DefaultActionProps {
  loading?: boolean;
  onConfirm: () => void;
  onCancel: () => void;
  hideCancel?: boolean;
}

interface ConfirmActionProps
  extends DefaultActionProps,
    Omit<
      IContentDialogProps,
      'title' | 'onClose' | 'actions' | 'disableActions' | 'hideActions' | 'closeText' | 'children'
    > {
  title?: string;
  children?: ReactNode;
}

function DefaultActions(props: DefaultActionProps) {
  const { onCancel, onConfirm, loading, hideCancel } = props;

  return (
    <Fragment>
      <Button disabled={loading} onClick={onConfirm} color='primary'>
        {loading ? <CircularProgress size='1rem' /> : 'Ok'}
      </Button>
      {!hideCancel && (
        <Button disabled={loading} onClick={onCancel} color='warning'>
          Cancel
        </Button>
      )}
    </Fragment>
  );
}

export default function ConfirmDialog(props: ConfirmActionProps) {
  const {
    title = 'Confirm',
    message = 'Please confirm that you would like to continue?',
    open,
    loading,
    onConfirm,
    onCancel,
    children,
    maxWidth = 'xs',
    hideCancel,
    ...rest
  } = props;

  return (
    <ContentDialog
      title={title}
      open={open}
      loading={loading}
      onClose={onCancel}
      actions={() => (
        <DefaultActions hideCancel={hideCancel} onCancel={onCancel} onConfirm={onConfirm} loading={loading} />
      )}
      maxWidth={maxWidth}
      {...rest}
    >
      <DialogContentText>{message}</DialogContentText>
      {children}
    </ContentDialog>
  );
}
